<template>
  <div id="container">
    <div class="higherPosition">
      <!--      <div class="modal-background-color">-->
      <!--&nbsp;-->
      <!--      </div>-->
      <!-- modals -->
      <transition name="component-fade-fast" mode="out-in" appear>
        <ModalTemplateComponent v-if="modal.template.windowVisible" />
      </transition>
      <transition name="component-fade-fast" mode="out-in" appear>
        <ModalErrorComponent v-if="modal.error.windowVisible" :prop-msg="modal.error.propMsg" />
      </transition>
      <transition name="component-fade-fast" mode="out-in" appear>
        <ModalWarningComponent
          v-if="modal.warning.windowVisible"
          :download-item-id="modal.warning.itemDiscardedListDownloadToken"
          :item-discarded="modal.warning.itemDiscarded"
          :prop-msg="modal.warning.propMsg"
        />
      </transition>
      <transition name="component-fade-fast" mode="out-in" appear>
        <ModalEmailBuilderComponent
          v-if="modal.emailBuilder.windowVisible"
          :client-security-code="modal.emailBuilder.clientSecurityCode"
          :event-id="modal.emailBuilder.eventId"
          :is-private-event="modal.emailBuilder.isPrivateEvent"
          :is-campaign="modal.emailBuilder.isCampaign"
          :is-confirmation-email="modal.emailBuilder.isConfirmationEmail"
          :user-id="modal.emailBuilder.userId"
          :lang="modal.emailBuilder.lang"
          :skip-selection="modal.emailBuilder.skipSelection"
          :email="modal.emailBuilder.email"
          :custom-field-tags="modal.emailBuilder.customFieldTags"
        >
        </ModalEmailBuilderComponent>
      </transition>

      <transition name="component-fade-fast" mode="out-in" appear>
        <ModalTemplateEmailBuilderComponent
          v-if="modal.templateEmailBuilder.windowVisible"
          :client-security-code="modal.templateEmailBuilder.clientSecurityCode"
          :event-id="modal.templateEmailBuilder.eventId"
          :user-id="modal.templateEmailBuilder.userId"
          :lang="modal.templateEmailBuilder.lang"
        />
      </transition>

      <transition name="component-fade-fast" mode="out-in" appear>
        <ModalPageBuilderComponent
          v-if="modal.pageBuilder.windowVisible"
          :event-id="modal.pageBuilder.eventId"
          :virtual-room-page-id="modal.pageBuilder.virtualRoomPageId"
          :video-rooms="modal.pageBuilder.videoRooms"
          :page="modal.pageBuilder.page"
        />
      </transition>

      <transition name="component-fade-fast" mode="out-in" appear>
        <ModalTemplatePageBuilderComponent
          v-if="modal.templatePageBuilder.windowVisible"
          :event-id="modal.templatePageBuilder.eventId"
          @templateSelected="modal.templatePageBuilder.templateSelected"
        />
      </transition>

      <transition name="component-fade-fast" mode="out-in" appear>
        <CreateSelectEventComponent
          v-if="selecteventshow"
          @component_event="manageComponentEvent"
        />
      </transition>
      <transition name="component-fade-fast" mode="out-in" appear>
        <ModalBulkAction
          v-if="modal.bulkAction.windowVisible"
          :users="modal.bulkAction.users"
          :title="modal.bulkAction.title"
          :visible="modal.bulkAction.windowVisible"
          :action="modal.bulkAction.action"
        />
      </transition>
    </div>

    <el-container :style="screenHeight">
      <el-header height="60px">
        <HeaderComponent
          :prop-app-name="appName"
          :prop-user-name="userName"
          @component_event="manageComponentEvent"
        />
      </el-header>
      <el-container :style="screenHeight">
        <el-aside
        id="sidebar" width="241px" class="sidebar-container">
          <SidebarComponent
            :height="screenHeightVal"
            @component_event="manageComponentEvent"
          />
        </el-aside>
        <el-container direction="vertical">
          <el-main ref="main-container" style="padding: 0;
    background: rgba(0, 0, 0, 0.65);">
            <section id="main-content" :class="bg" :style="screenHeight">
              <section class="wrapper wrapper-index" >
              <!-- :style="'min-height: ' + (screenHeightVal -166) + 'px;'  "> -->
                <div class="container-home">
                  <transition name="component-fade" mode="out-in" appear>
                    <slot />
                  </transition>
                  <!-- <el-footer height="100px"> -->
                    <!-- </el-footer> -->
                </div>
              </section>
                    <public-footer :full-width="true" class="dashboard-footer" />
            </section>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
    <a href="https://academy.penguinpass.it" target="_blank" class="footer-link" style="bottom: 30px; right: 20px; position:fixed; text-decoration: none">
      <div style="width: 50px; height: 50px; background-color: #f7a30c; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: white; cursor: pointer; font-size: 35px;">
        <i class="fa fa-question" aria-hidden="true"></i>
      </div>
    </a>
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap.min";

import $ from "jquery";
import "webcomponentsjs/lite";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.min";
import "moment/min/moment.min";
import "jquery-sparkline/jquery.sparkline.min";
import "../assets/js/dashboard/modal-effect/classie";
import "../assets/js/dashboard/common";
import SidebarComponent from "../components/Sidebar.vue";
import HeaderComponent from "./../components/Header.vue";
import FooterComponent from "./../components/Footer.vue";
import CreateSelectEventComponent from "./../components/CreateSelectEvent.vue";
import userdata from "./../userdata.js";

/** MODALS */
import ModalTemplateComponent from "./../components/modals/ModalTemplate.vue";
import ModalErrorComponent from "./../components/modals/ModalError.vue";
import ModalWarningComponent from "./../components/modals/ModalWarning.vue";
import ModalEmailBuilderComponent from "./../components/modals/ModalEmailBuilder.vue";
import ModalTemplateEmailBuilderComponent from "./../components/modals/ModalTemplateEmailBuilder.vue";
import ModalPageBuilderComponent from "./../components/modals/ModalPageBuilder.vue";
import ModalTemplatePageBuilderComponent from "./../components/modals/ModalTemplatePageBuilder.vue";
import ModalBulkAction from "./../components/modals/ModalBulkAction.vue";
// import ModalChat from './../components/modals/ModalChat.vue';

import routes from "../router";
import PublicFooter from '@/components/PublicFooter.vue';

// This is required for styling the component. You can also write your own stylesheet. You can find my styles inside the vue component

export default {
  components: {
    // 'chat' : Chat,
    // 'v-link' : VLink,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    CreateSelectEventComponent,
    /** MODALS */
    ModalErrorComponent,
    ModalTemplateComponent,
    ModalWarningComponent,
    ModalEmailBuilderComponent,
    ModalTemplateEmailBuilderComponent,
    ModalPageBuilderComponent,
    ModalTemplatePageBuilderComponent,
    ModalBulkAction,
    PublicFooter
    // 'modal-chat' : ModalChat,
  },
  data() {
    return {
      pusherChannel: null,
      appName: /*userdata.app_settings.app_name === 'gobella' ? 'GOBELLA' : */ "PENGUINPASS",
      userName: userdata.user_name ? userdata.user_name : "Organizer",
      selecteventshow: false,
      pusherTxt: "",
      toastMessage: "",
      backgroundImage: require('../assets/img/bg-5.jpg'),
      strings: {},

      /** Email Builder Properties */

      /** MODALs Properties */
      modal: {
        warning: {
          windowVisible: false,
          propMsg: "Warning",
          itemDiscarded: 33,
          itemDiscardedListDownloadToken: ""
        },
        error: {
          propMsg: "Errore Errore Errore",
          windowVisible: false
        },
        template: {
          windowVisible: false
        },
        emailBuilder: {
          windowVisible: false,
          lang: "it",
          userId: userdata.user_id,
          eventId: "",
          clientSecurityCode: userdata.client_security_code,
          skipSelection: true,
          isConfirmationEmail: false,
          isPrivate: false,
          isCampaign: true,
          email: {},
          customFieldTags: []
        },
        templateEmailBuilder: {
          windowVisible: false,
          eventId: "",
          userId: userdata.user_id,
          clientSecurityCode: userdata.client_security_code
        },
        pageBuilder: {
          windowVisible: false,
          eventId: "",
          userId: userdata.user_id,
          page: "",
          virtualRoomPageId: "",
          videoRooms: [],
          clientSecurityCode: userdata.client_security_code
        },
        templatePageBuilder: {
          windowVisible: false,
          eventId: "",
          virtualRoomPageId: "",
          videoRooms: [],
          templateSelected: (code) => {}
        },
        bulkAction: {
          windowVisible: false,
          title: "",
          users: [],
          action: ""
        },
        changeLanguageListener: null,
        eventListenerr: null,
        simpleToastListener: null,
        simpleToastErrorListener: null,
        longerToastListener: null,
        notAuthorizedOptionToastListener: null,
        component_eventListener: null,
        modalViewListener: null,
        closeErrorModalListener: null,
        modalWarningViewListener: null,
        modalEmailBuilderViewListener: null,
        modalTemplateEmailBuilderViewListener: null,
        modalPageBuilderViewListener: null,
        modalTemplatePageBuilderViewListener: null,
        toggleModalBulkActionListener: null,
        scrollX: 0,
        scrollY: 0,
        screenHeight: " height: 700px;",
        mainMinHeight: " min-height: 700px;",
        screenHeightVal: 700
      }
    };
  },
  computed: {
  },
  watch: {
  },
  beforeDestroy() {
    try {
      window.removeEventListener("resize", this.resize());
      if (this.changeLanguageListener) {
        this.changeLanguageListener.remove();
        this.changeLanguageListener = null;
      }
      if (this.eventListenerr) {
        this.eventListenerr.remove();
        this.eventListenerr = null;
      }
      if (this.simpleToastListener) {
        this.simpleToastListener.remove();
        this.simpleToastListener = null;
      }
      if (this.simpleToastErrorListener) {
        this.simpleToastErrorListener.remove();
        this.simpleToastErrorListener = null;
      }
      if (this.longerToastListener) {
        this.longerToastListener.remove();
        this.longerToastListener = null;
      }
      if (this.notAuthorizedOptionToastListener) {
        this.notAuthorizedOptionToastListener.remove();
        this.notAuthorizedOptionToastListener = null;
      }
      if (this.component_eventListener) {
        this.component_eventListener.remove();
        this.component_eventListener = null;
      }
      if (this.modalViewListener) {
        this.modalViewListener.remove();
        this.modalViewListener = null;
      }
      if (this.closeErrorModalListener) {
        this.closeErrorModalListener.remove();
        this.closeErrorModalListener = null;
      }
      if (this.modalWarningViewListener) {
        this.modalWarningViewListener.remove();
        this.modalWarningViewListener = null;
      }
      if (this.modalEmailBuilderViewListener) {
        this.modalEmailBuilderViewListener.remove();
        this.modalEmailBuilderViewListener = null;
      }
      if (this.modalTemplateEmailBuilderViewListener) {
        this.modalTemplateEmailBuilderViewListener.remove();
        this.modalTemplateEmailBuilderViewListener = null;
      }
      if (this.modalPageBuilderViewListener) {
        this.modalPageBuilderViewListener.remove();
        this.modalPageBuilderViewListener = null;
      }
      if (this.modalTemplatePageBuilderViewListener) {
        this.modalTemplatePageBuilderViewListener.remove();
        this.modalTemplatePageBuilderViewListener = null;
      }
      if (this.toggleModalBulkActionListener) {
        this.toggleModalBulkActionListener.remove();
        this.toggleModalBulkActionListener = null;
      }
    } catch {}
  },
  created() {
    const mainContext = this;
    this.modal.templatePageBuilder.templateSelected = (code) => {
      mainContext.modal.pageBuilder.page = code;
      mainContext.modal.templatePageBuilder.windowVisible = false;
      mainContext.modal.pageBuilder.windowVisible = true;
      mainContext.modal.pageBuilder.eventId = mainContext.modal.templatePageBuilder.eventId;
      mainContext.modal.pageBuilder.virtualRoomPageId = mainContext.modal.templatePageBuilder.virtualRoomPageId;
      mainContext.modal.pageBuilder.videoRooms = mainContext.modal.templatePageBuilder.videoRooms;
    };

    this.strings = this.$root.strings;
    this.modal.emailBuilder.lang = this.$root.currentLanguage;
    // this.modal.chat.lang = this.$root.currentLanguage;
    mainContext.bg = mainContext.$root.mainBackgroundClass;

    mainContext.$root.bus.addListener(
      "setLanguage",
      () => {
        mainContext.strings = mainContext.$root.strings;
      },
      this
    );

    /** EVENT LISTENER GLOBAL */
    this.changeLanguageListener = this.$root.bus.addListener(
      "changeLanguage",
      lang => {
        if (lang.lang === "it") {
          mainContext.$root.strings = mainContext.$root.messages_it;
          mainContext.lang = "it";
          mainContext.$root.currentLanguage = "it";
        }
        if (lang.lang === "en") {
          mainContext.$root.strings = mainContext.$root.messages_en;
          mainContext.lang = "en";
          mainContext.$root.currentLanguage = "en";
        }
        if (lang.lang === "fr") {
          mainContext.$root.strings = mainContext.$root.messages_fr;
          mainContext.lang = "fr";
          mainContext.$root.currentLanguage = "fr";
        }
        if (lang.lang === "ar") {
          mainContext.$root.strings = mainContext.$root.messages_ar;
          mainContext.lang = "ar";
          mainContext.$root.currentLanguage = "ar";
        }

        mainContext.$root.bus.emit("setLanguage");
      },
      this
    );

    /** Test Listener Global */
    this.eventListenerr = this.$root.bus.addListener(
      "event",
      (x, y) => {
        mainContext.toastMessage = "This is a TOAST";
        mainContext.$root.toast();
      },
      this
    );

    /** SimpleToast Manager */
    this.simpleToastListener = this.$root.bus.addListener(
      "simpleToast",
      msg => {
        mainContext.toastMessage = msg;
        // mainContext.$root.toast();
        mainContext.$notify({
          title: "Info",
          message: msg,
          position: "bottom-right",
          type: "info",
          duration: 3000
        });
      },
      this
    );

    /** SimpleToast Error Manager */
    this.simpleToastErrorListener = this.$root.bus.addListener(
      "simpleToastError",
      msg => {
        mainContext.toastMessage = msg;
        // mainContext.$root.toast();
        mainContext.$notify({
          title: "",
          message: msg,
          position: "bottom-right",
          type: "error",
          duration: 5000
        });
      },
      this
    );

    /** SimpleToast Manager */
    this.longerToastListener = this.$root.bus.addListener(
      "longerToast",
      msg => {
        mainContext.toastMessage = msg;
        // mainContext.$root.toast();
        mainContext.$notify({
          title: "Info",
          message: msg,
          position: "bottom-right",
          type: "info",
          duration: 6000
        });
      },
      this
    );

    /** SimpleToast Manager */
    this.notAuthorizedOptionToastListener = this.$root.bus.addListener(
      "notAuthorizedOptionToast",
      () => {
        mainContext.toastMessage = mainContext.strings.main._notauthorizedoption;
        // mainContext.$root.toastError();
        mainContext.$notify({
          title: "",
          message: mainContext.strings.main._notauthorizedoption,
          position: "bottom-right",
          type: "error",
          duration: 3000
        });
      },
      this
    );

    /** ComponentEvent Manager */
    this.component_eventListener = this.$root.bus.addListener(
      "component_event",
      e => {
        mainContext.manageComponentEvent(e);
      },
      this
    );

    /** Modal Manager */
    this.modalViewListener = this.$root.bus.addListener(
      "modalView",
      (e, msg) => {
        if (e === "test") {
          mainContext.modal.template.windowVisible = true;
        }
        if (e === "error") {
          mainContext.modal.error.windowVisible = true;
          mainContext.modal.error.propMsg = msg;
        }
        if (e === "fadeout") {
          mainContext.modal.warning.windowVisible = false;
          mainContext.modal.error.windowVisible = false;
          mainContext.modal.template.windowVisible = false;
          mainContext.modal.emailBuilder.windowVisible = false;
          mainContext.modal.templateEmailBuilder.windowVisible = false;
          mainContext.modal.pageBuilder.windowVisible = false;
          mainContext.modal.templatePageBuilder.windowVisible = false;
          // mainContext.modal.chat.windowVisible = false;
          document.body.classList.remove("disable-scroll");
          window.scrollTo(mainContext.scrollX, mainContext.scrollY);
        }
      },
      this
    );

    this.closeErrorModalListener = this.$root.bus.addListener(
      "closeErrorModal",
      (e, msg) => {
        mainContext.modal.error.windowVisible = false;
      },
      this
    );

    /** Modal Warning */
    this.modalWarningViewListener = this.$root.bus.addListener(
      "modalWarningView",
      (e, numberAndToken) => {
        if (e === "warning") {
          mainContext.modal.warning.windowVisible = true;
          mainContext.modal.warning.itemDiscarded = numberAndToken[0];
          mainContext.modal.warning.itemDiscardedListDownloadToken = numberAndToken[1];
        }
      },
      this
    );

    /** Modal Email Builder */
    this.modalEmailBuilderViewListener = this.$root.bus.addListener(
      "modalEmailBuilderView",
      ({ eventId, isConfirmationEmail, email, customFieldTags = [], isPrivateEvent = false, isCampaign = true }) => {
        mainContext.modal.emailBuilder.windowVisible = true;
        mainContext.modal.emailBuilder.eventId = eventId;
        mainContext.modal.emailBuilder.skipSelection = true;
        mainContext.modal.emailBuilder.email = email;
        mainContext.modal.emailBuilder.customFieldTags = customFieldTags;
        mainContext.modal.emailBuilder.isConfirmationEmail = isConfirmationEmail;
        mainContext.modal.emailBuilder.isPrivateEvent = isPrivateEvent;
        mainContext.modal.emailBuilder.isCampaign = isCampaign;
        mainContext.scrollX = window.scrollX;
        mainContext.scrollY = window.scrollY;
        document.body.classList.add("disable-scroll");
      },
      this
    );

    /** Modal Email Builder */
    this.modalTemplateEmailBuilderViewListener = this.$root.bus.addListener(
      "modalTemplateEmailBuilderView",
      ({ eventId, customFieldTags = [],isConfirmationEmail, isPrivateEvent = false, isCampaign = true }) => {
        mainContext.modal.emailBuilder.windowVisible = true;
        mainContext.modal.emailBuilder.eventId = eventId;
        mainContext.modal.emailBuilder.skipSelection = false;
        mainContext.modal.emailBuilder.customFieldTags = customFieldTags;
        mainContext.modal.emailBuilder.customFieldTags = customFieldTags;
        mainContext.modal.emailBuilder.isConfirmationEmail = isConfirmationEmail;
        mainContext.modal.emailBuilder.isPrivateEvent = isPrivateEvent;
        mainContext.modal.emailBuilder.isCampaign = isCampaign;
        mainContext.scrollX = window.scrollX;
        mainContext.scrollY = window.scrollY;
        document.body.classList.add("disable-scroll");
      },
      this
    );

    /** Modal Page Builder */
    this.modalPageBuilderViewListener = this.$root.bus.addListener(
      "modalPageBuilderView",
      ({ eventId, virtualRoomPageId, videoRooms, page }) => {
        mainContext.modal.pageBuilder.windowVisible = true;
        mainContext.modal.pageBuilder.eventId = eventId;
        mainContext.modal.pageBuilder.virtualRoomPageId = virtualRoomPageId;
        mainContext.modal.pageBuilder.videoRooms = videoRooms;
        mainContext.modal.pageBuilder.page = page;
        mainContext.scrollX = window.scrollX;
        mainContext.scrollY = window.scrollY;
        document.body.classList.add("disable-scroll");
      },
      this
    );

    /** Modal Page Builder */
    this.modalTemplatePageBuilderViewListener = this.$root.bus.addListener(
      "modalTemplatePageBuilderView",
      ({ eventId, virtualRoomPageId, videoRooms }) => {
        mainContext.modal.templatePageBuilder.windowVisible = true;
        mainContext.modal.templatePageBuilder.eventId = eventId;
        mainContext.modal.templatePageBuilder.virtualRoomPageId = virtualRoomPageId;
        mainContext.modal.templatePageBuilder.videoRooms = videoRooms;
        mainContext.scrollX = window.scrollX;
        mainContext.scrollY = window.scrollY;
        document.body.classList.add("disable-scroll");
      },
      this
    );

    /** Modal Bulk Action */
    this.toggleModalBulkActionListener = this.$root.bus.addListener(
      "toggleModalBulkAction",
      ({ show, title, users, action }) => {
        if (show) {
          mainContext.modal.bulkAction.title = title;
          mainContext.modal.bulkAction.users = users;
          mainContext.modal.bulkAction.action = action;
          mainContext.scrollX = window.scrollX;
          mainContext.scrollY = window.scrollY;
          document.body.classList.add("disable-scroll");
        } else {
          mainContext.modal.bulkAction.title = "";
          mainContext.modal.bulkAction.users = [];
          mainContext.modal.bulkAction.action = "";
          document.body.classList.remove("disable-scroll");
          window.scrollTo(mainContext.scrollX, mainContext.scrollY);
        }
        mainContext.modal.bulkAction.windowVisible = show;
      },
      this
    );
    window.addEventListener("resize", this.resize());
  },
  mounted() {
    this.resize();
    $(document).ready(() => {
      $(".accordion")
        .find(".accordion-toggle")
        .click(function() {
          $(this)
            .next()
            .slideToggle("600");
          $(".accordion-content")
            .not($(this).next())
            .slideUp("600");
        });
      $(".accordion-toggle").on("click", function() {
        $(".advance-settings-container .active").removeClass("active");
        $(this).toggleClass("active");
      });
    });
    this.updateBg();
  },
  methods: {
    resize() {
      this.screenHeight= ' height:'+ (window.innerHeight - 60) +'px; ';
      this.screenHeightVal= window.innerHeight - 60;
    },
    updateBg(){
      const container = document.querySelector('#container');
      if(this.$store.state.user.backgroundUrl)
        container.style.backgroundImage = `url(${this.$store.getters.user.backgroundUrl})`;
      else
        container.style.backgroundImage = `url(${this.backgroundImage})`;
    
    },
    manageComponentEvent: function(e) {
      const method = e.split("|")[0].replace(/[\-]/g, "");
      const attribute = e.split("|")[1].replace(/[\-]/g, "");
      const str = "this." + method + "('" + attribute + "')";
      //console.log(str);
      eval(str);
    },
    createselectevent: function(v) {
      if (v === "true") {
        this.show_createselecteventcomponent_away();
      }

      if (v === "trigNewPrivateEvent") {
        this.$router.push({ path: "/events/create/private" }).catch(error => {});
        this.hide_createselecteventcomponent_away();
      }

      if (v === "trigNewPublicEvent") {
        this.$router.push({ path: "/events/create/public" }).catch(error => {});
        this.hide_createselecteventcomponent_away();
      }

      this.hide_createselecteventcomponent_away();
    },
    sidebar: function(v) {
      if (v === "newEvent") {
        this.show_createselecteventcomponent_away();
      }
      if (v === "allEvents") {
        this.$router.push({ path: "/events" }).catch(err => {});
      }
      if (v === "archived") {
        this.$router.push({ path: "/events/archive" }).catch(err => {});
      }
      if (v === "recordings") {
        this.$router.push({ path: "/recordings" }).catch(err => {});
      }
      if (v === "notify") {
        this.$router.push({ path: "/notify" }).catch(err => {});
      }
      if (v === "teamMembers") {
        this.$router.push({ path: "/team/members" }).catch(err => {});
      }
      if (v === "eventGroup") {
        this.$router.push({ path: "/event-groups" }).catch(err => {});
      }
      if (v === "helpdesk") {
        this.$router.push({ path: "/helpdesk/it-home" }).catch(err => {});
      }
      if (v === "allPrivacyPolicies") {
        this.$router.push({ path: "/privacy-policies" }).catch(err => {});
      }
    },
    functionbar: function(v) {
      if (v === "newEvent") {
        this.show_createselecteventcomponent_away();
      }
    },
    newevent: function(v) {
      if (v === "cancel") {
        this.$router.replace({ path: "/" });
      }
    },
    show_createselecteventcomponent_away: function() {
      this.selecteventshow = true;
    },
    hide_createselecteventcomponent_away: function() {
      this.selecteventshow = false;
    }
  }
};
</script>

<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~animate.css/animate.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~gritter/css/jquery.gritter.css";
@import "~rome/dist/rome.min.css";
@import "~vue-toast/dist/vue-toast.min.css";
@import "../assets/css/dashboard/accordion.css";
@import "../assets/css/dashboard/spinner.css";
@import "../assets/css/dashboard/modal-effect.css";
@import "../assets/css/dashboard/style-responsive.css";
@import "../assets/css/dashboard/main.css";

/*<link rel="stylesheet" type="text/css" href="{{url("css/dashboard/uielement.css")}}">*/

.component-fade-fast-enter,
.component-fade-fast-leave-active {
  opacity: 0;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.9s ease;
}
.component-fade-enter,
.component-fade-leave-active {
  opacity: 0;
}

.higherPosition {
  position: fixed;
  z-index: 1003;
}
.hp {
  position: absolute;
  top: 50px;
  left: 60px;
  color: #ffffff;
  z-index: 1004;
}
.hpplus {
  position: absolute;
  top: 250px;
  left: 260px;
  color: #000000;
  z-index: 1004;
}
body {
  background: #070f13;
}
</style>
