var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "policy-action" },
    [
      _vm.joinAction === "accept"
        ? [
            _vm.joinStatus === 1 && _vm.privacyPolicies.length > 0
              ? _c(
                  "div",
                  { staticClass: "field actions" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.string.joinPage._privacy_policy) +
                          "\n      "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.privacyPolicies, function (policy) {
                      return _c(
                        "div",
                        { key: policy.id, staticClass: "single-action" },
                        [
                          _c(
                            "label",
                            { staticClass: "control control-checkbox" },
                            [
                              policy.mandatory
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "padding-bottom": "5px" },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            "(" +
                                              _vm.string.joinPage._mandatory +
                                              ")"
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "privacy-text",
                                staticStyle: { "white-space": "break-spaces" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    policy.teamPrivacyPolicyId.text
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: policy.checked,
                                    expression: "policy.checked",
                                  },
                                ],
                                attrs: {
                                  required: policy.mandatory,
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(policy.checked)
                                    ? _vm._i(policy.checked, null) > -1
                                    : policy.checked,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = policy.checked,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              policy,
                                              "checked",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              policy,
                                              "checked",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(policy, "checked", $$c)
                                      }
                                    },
                                    function ($event) {
                                      return _vm.checkPolicy(
                                        policy._id,
                                        policy.checked
                                      )
                                    },
                                  ],
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "control_indicator" }),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.timeSlotEnabled &&
            _vm.joinStatus === 1 &&
            _vm.timeSlotArray.length > 0
              ? _c(
                  "div",
                  { staticClass: "field actions" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.string.timeSlotManager._title) +
                          "\n      "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.timeSlotArray, function (ref) {
                      var slug = ref[0]
                      var cField = ref[1]
                      return _c(
                        "div",
                        { key: slug, staticClass: "single-action" },
                        [
                          _c("label", [
                            _vm._v(
                              "\n          " +
                                _vm._s(cField.options.question) +
                                "\n          " +
                                _vm._s(
                                  cField.rsvpMandatory
                                    ? "(" + _vm.string.joinPage._mandatory + ")"
                                    : ""
                                ) +
                                "\n        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticClass: "rsvp-select",
                              class: {
                                invalid:
                                  _vm.showErrors &&
                                  cField.rsvpMandatory &&
                                  _vm.answerList[slug].length < 1,
                              },
                              staticStyle: { display: "block" },
                              attrs: {
                                "popper-class": "grayscale-popper",
                                clearable: "",
                                placeholder: cField.options.question,
                              },
                              on: {
                                change: function (value) {
                                  return _vm.onUpdateTimeSlot(
                                    _vm.customFieldDataMap,
                                    slug,
                                    value
                                  )
                                },
                              },
                              model: {
                                value: _vm.answerList[slug],
                                callback: function ($$v) {
                                  _vm.$set(_vm.answerList, slug, $$v)
                                },
                                expression: "answerList[slug]",
                              },
                            },
                            _vm._l(
                              Object.entries(cField.options.answers),
                              function (ref) {
                                var answerSlug = ref[0]
                                var answer = ref[1]
                                return _c(
                                  "el-option",
                                  {
                                    key: answerSlug,
                                    attrs: {
                                      hidden:
                                        answer.disabled ||
                                        (answer.maxLimit > 0 &&
                                          answer.limit >= answer.maxLimit),
                                      label: answer.label,
                                      value: answerSlug,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(answer.label) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.joinStatus === 1 &&
            (_vm.memberCustomFieldArray.length > 0 ||
              _vm.customFieldArray.length > 0)
              ? _c(
                  "div",
                  { staticClass: "field actions" },
                  [
                    _c("h4", { staticClass: "text-heading" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.string.joinPage._custom_fields) +
                          "\n      "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.memberCustomFieldArray, function (ref) {
                      var slug = ref[0]
                      var cField = ref[1]
                      return _c(
                        "div",
                        { key: slug, staticClass: "single-action" },
                        [
                          _c("label", [
                            _vm._v(
                              "\n          " +
                                _vm._s(cField.options.question) +
                                "\n          " +
                                _vm._s(
                                  cField.rsvpMandatory
                                    ? "(" + _vm.string.joinPage._mandatory + ")"
                                    : ""
                                ) +
                                "\n        "
                            ),
                          ]),
                          _vm._v(" "),
                          cField.type === "MULTIPLE_CHOICE"
                            ? _c(
                                "el-select",
                                {
                                  staticClass: "rsvp-select",
                                  class: {
                                    invalid:
                                      _vm.showErrors &&
                                      cField.rsvpMandatory &&
                                      _vm.memberAnswerList[slug].length < 1,
                                  },
                                  staticStyle: { display: "block" },
                                  attrs: {
                                    "popper-class": "grayscale-popper",
                                    multiple: "",
                                    "collapse-tags": "",
                                    filterable: "",
                                    "multiple-limit":
                                      cField.options.maxSelection,
                                    placeholder: cField.options.question,
                                  },
                                  on: {
                                    change: function (value) {
                                      return _vm.onUpdateMultipleChoice(
                                        _vm.memberCustomFieldDataMap,
                                        slug,
                                        value
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.memberAnswerList[slug],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.memberAnswerList, slug, $$v)
                                    },
                                    expression: "memberAnswerList[slug]",
                                  },
                                },
                                _vm._l(
                                  Object.entries(cField.options.answers),
                                  function (ref) {
                                    var answerSlug = ref[0]
                                    var answer = ref[1]
                                    return _c(
                                      "el-option",
                                      {
                                        key: answerSlug,
                                        attrs: {
                                          hidden:
                                            answer.disabled ||
                                            (answer.maxLimit > 0 &&
                                              answer.limit >= answer.maxLimit),
                                          label: answer.label,
                                          value: answerSlug,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(answer.label) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.memberCustomFieldDataMap[slug],
                                    expression:
                                      "memberCustomFieldDataMap[slug]",
                                  },
                                ],
                                staticClass: "single-input",
                                class: {
                                  invalid:
                                    _vm.showErrors &&
                                    cField.rsvpMandatory &&
                                    !_vm.memberCustomFieldDataMap[slug],
                                },
                                attrs: {
                                  "aria-label": "",
                                  type: "text",
                                  placeholder: cField.options.question,
                                  required: "",
                                },
                                domProps: {
                                  value: _vm.memberCustomFieldDataMap[slug],
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.memberCustomFieldDataMap,
                                      slug,
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.customFieldArray, function (ref) {
                      var slug = ref[0]
                      var cField = ref[1]
                      return _c(
                        "div",
                        { key: slug, staticClass: "single-action" },
                        [
                          _c("label", [
                            _vm._v(
                              "\n          " +
                                _vm._s(cField.options.question) +
                                "\n          " +
                                _vm._s(
                                  cField.rsvpMandatory
                                    ? "(" + _vm.string.joinPage._mandatory + ")"
                                    : ""
                                ) +
                                "\n        "
                            ),
                          ]),
                          _vm._v(" "),
                          cField.type === "TEXT_ONLY"
                            ? _c("div", {
                                staticClass: "control-label ql-editor",
                                style: {
                                  textAlign: cField.options.alignment
                                    ? cField.options.alignment
                                    : "left",
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    "" + cField.options.content
                                  ),
                                },
                              })
                            : cField.type === "MULTIPLE_CHOICE"
                            ? _c(
                                "el-select",
                                {
                                  staticClass: "rsvp-select",
                                  class: {
                                    invalid:
                                      _vm.showErrors &&
                                      cField.rsvpMandatory &&
                                      _vm.answerList[slug].length < 1,
                                  },
                                  staticStyle: { display: "block" },
                                  attrs: {
                                    "popper-class": "grayscale-popper",
                                    multiple: "",
                                    "collapse-tags": "",
                                    filterable: "",
                                    "multiple-limit":
                                      cField.options.maxSelection,
                                    placeholder: cField.options.question,
                                  },
                                  on: {
                                    change: function (value) {
                                      return _vm.onUpdateMultipleChoice(
                                        _vm.customFieldDataMap,
                                        slug,
                                        value
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.answerList[slug],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.answerList, slug, $$v)
                                    },
                                    expression: "answerList[slug]",
                                  },
                                },
                                _vm._l(
                                  Object.entries(cField.options.answers),
                                  function (ref) {
                                    var answerSlug = ref[0]
                                    var answer = ref[1]
                                    return _c(
                                      "el-option",
                                      {
                                        key: answerSlug,
                                        attrs: {
                                          hidden:
                                            answer.disabled ||
                                            (answer.maxLimit > 0 &&
                                              answer.limit >= answer.maxLimit),
                                          label: answer.label,
                                          value: answerSlug,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(answer.label) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : cField.type === "DATE"
                            ? _c("input", {
                                staticClass: "form-control date-select",
                                class: {
                                  invalid:
                                    _vm.showErrors &&
                                    cField.registrationFormMandatory &&
                                    !_vm.memberCustomFieldDataMap[slug],
                                },
                                attrs: {
                                  "aria-label": "",
                                  type: "date",
                                  required: cField.registrationFormMandatory,
                                },
                                on: {
                                  change: function (value) {
                                    return _vm.onUpdateDateField(
                                      slug,
                                      value.target.value
                                    )
                                  },
                                },
                              })
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.customFieldDataMap[slug],
                                    expression: "customFieldDataMap[slug]",
                                  },
                                ],
                                staticClass: "single-input",
                                class: {
                                  invalid:
                                    _vm.showErrors &&
                                    cField.rsvpMandatory &&
                                    !_vm.customFieldDataMap[slug],
                                },
                                attrs: {
                                  "aria-label": "",
                                  type: "text",
                                  placeholder: cField.options.question,
                                  required: "",
                                },
                                domProps: {
                                  value: _vm.customFieldDataMap[slug],
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.customFieldDataMap,
                                      slug,
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.joinStatus === 1 &&
            !_vm.acquaintanceAdded &&
            (_vm.setMaxAcquaintanceOnJoin || _vm.setAcquaintanceInfoOnJoin) &&
            _vm.maxAcquaintance > 0
              ? _c("div", { staticClass: "acquaintance-container" }, [
                  _c("div", { attrs: { id: "setPartner" } }, [
                    _vm.setMaxAcquaintanceOnJoin
                      ? _c("div", { staticClass: "numbers-row" }, [
                          _c("h4", { staticClass: "text-heading" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.string.joinPage._number_of_guests) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col-lg-12",
                              staticStyle: { "font-size": "18px" },
                            },
                            [
                              _c("div", { staticClass: "container-numbers" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dec button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDecrease()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                -\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(_vm.counterValue))]),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "inc button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onIncrease()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                +\n              "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.maxAcquaintance > 0 &&
                    _vm.setAcquaintanceInfoOnJoin &&
                    _vm.acquaintanceInfoList.length > 0
                      ? _c("h3", { staticClass: "text-heading" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.string.joinPage._info_of_guests) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.maxAcquaintance > 0 && _vm.setAcquaintanceInfoOnJoin
                      ? _c(
                          "div",
                          { attrs: { id: "guest_name_form" } },
                          _vm._l(
                            _vm.acquaintanceInfoList,
                            function (plusOne, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "guest_name_form " },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "guest_name_title" },
                                    [
                                      _c("p", { staticClass: "text-heading" }, [
                                        _vm._v(
                                          _vm._s(_vm.string.joinPage._guest) +
                                            " " +
                                            _vm._s(index + 1)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "guest-row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "guest-info-col" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: plusOne.name,
                                              expression: "plusOne.name",
                                            },
                                          ],
                                          staticClass: "single-input",
                                          attrs: {
                                            "aria-label": "",
                                            type: "text",
                                            placeholder:
                                              _vm.string.joinPage._guest_name +
                                              " " +
                                              (index + 1) +
                                              " (" +
                                              _vm.string.joinPage._mandatory +
                                              ")",
                                            required: "",
                                          },
                                          domProps: { value: plusOne.name },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                plusOne,
                                                "name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "guest-info-col" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: plusOne.surname,
                                              expression: "plusOne.surname",
                                            },
                                          ],
                                          staticClass: "single-input",
                                          attrs: {
                                            "aria-label": "",
                                            type: "text",
                                            placeholder:
                                              _vm.string.joinPage
                                                ._guest_surname +
                                              " " +
                                              (index + 1) +
                                              " (" +
                                              _vm.string.joinPage._mandatory +
                                              ")",
                                            required: "",
                                          },
                                          domProps: { value: plusOne.surname },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                plusOne,
                                                "surname",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "guest-info-col" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: plusOne.email,
                                              expression: "plusOne.email",
                                            },
                                          ],
                                          staticClass: "single-input",
                                          attrs: {
                                            "aria-label": "",
                                            type: "text",
                                            placeholder:
                                              "Email " +
                                              (index + 1) +
                                              " (" +
                                              _vm.string.joinPage._mandatory +
                                              ")",
                                            required: "",
                                          },
                                          domProps: { value: plusOne.email },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                plusOne,
                                                "email",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.joinStatus === 1
        ? _c(
            "a",
            {
              staticClass: "confirm-button",
              on: {
                click: function ($event) {
                  _vm.joinAction === "accept"
                    ? _vm.confirmAccept()
                    : _vm.confirmDecline()
                },
              },
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.joinAction === "accept"
                      ? _vm.string.joinPage._join_confirm
                      : _vm.string.joinPage._join_confirm_decline
                  ) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }