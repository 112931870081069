export default {
  commonActions: {
    save: "enregistrer",
    saveSettings: "enregistrer les paramètres",
    submit: "soumettre",
    send: "envoyer",
    edit: "editer",
    upload: "télécharger",
    download: "télécharger",
    open: "open",
    close: "fermer",
    delete: "supprimer",
    passwordPolicy: "Le mot de passe doit comporter au moins 8 caractères, doit contenir des lettres, des chiffres et des caractères spéciaux"
  },
  useExistingPolicy: {
    _labelActive: "Actif",
    _labelMandatory: "Obligatoire",
    _labelNotMandatory: "Non obligatoire",
    _labelOnlyThisEvent: "Uniquement pour cet événement",
    _noPolicies: "Pas de politiques",
    _name: "Nom de la politique de confidentialité :"
  },
  createPolicy: {
    _small: "Politique de protection de la vie privée gestion des événements",
    _subtitle: "",
    _policyName: "Nom de la politique de confidentialité",
    _policyText: "Texte de la politique de confidentialité",
    _createPolicy: "Créer"
  },
  privacyPolicy: {
    _createNew: "CRÉER VOTRE POLITIQUE DE PROTECTION DE LA VIE PRIVÉE",
    _chooseExisting: "CRÉATION D'UNE POLITIQUE DE PROTECTION DE LA VIE PRIVÉE",
    _save: "SAVE"
  },
  groupManager: {
    _noDataMessage: "Pas d'utilisateurs dans le groupe des cuisses",
    _noDataHint: "Ajouter un utilisateur à partir d'ici"
  },
  modalError: {
    _title: "Quelque chose ne va pas...",
    _buttonClose: "Fermer",
    _unManagedError: "Erreur non traitée"
  },
  modalWarning: {
    _title: "Avertissement",
    _buttonClose: "Fermer",
    _warningNotImportedMessage0: "Certains enregistrements de votre liste ont été jetés.",
    _warningNotImportedMessage1: "Ce problème se produit parfois en raison d'une erreur de formatage dans votre liste ou parce qu'un invité ayant le même courriel existe déjà sur la liste des invités de votre événement.",
    _warningNotImportedMessage2: "Vérifiez votre liste et réessayez.",
    _warningNotImportedLinkDownload: "Télécharger la liste des éléments rejetés",
    _itemNotImportedRecap: "Nombre d'articles non importés",
    _unManagedError: "Erreur non traitée"
  },
  functionBar: {
    _new: "Nouveau",
    _events: "Événements",
    _tutorial: "Tutoriel",
    _report: "Rapport",
    _archive: "Archives",
    _search: "Recherchez"
  },
  attachments: {
    _selectattachments: "Choisissez une annexe",
    _putattachmentstoevent: "Joignez des fichiers à votre événement, ils seront affichés sur l'application",
    _help: "Seule l'extension .pdf est autorisée ",
    _attachmentTypeSelect: "Type de pièce jointe",
    _map: "Plan d'étage",
    _brochure: "Brochure",
    _invite: "Invitation",
    _press: "Communiqué de presse",
    _upload: "Télécharger",
    _loadingMessage: "Attendre",
    _mandatoryFields: "Choisissez un fichier dans vos archives ",
    _filetype: "Type de document *",
    _uploadsuccess: "Réussir l'upload",
    _uploadfailed: "Echec du téléchargement",
    _deleteAttachment: "Supprimer la pièce jointe"
  },
  attendeeMaxLimit: {
    _title: "Limite maximale de participants",
    _subtitle: "Achat",
    _placeholder: "Nombre maximum de réservations.",
    _submit: "Sauvegarder",
    _updateDone: "Mise à jour",
    _error: "erreur non traitée"
  },
  coverImage: {
    _updateDone: "Mise à jour",
    _loadingMessage: "Attendre",
    _imgNotices: "Taille recommandée 600x350 px, max 1 MB",
    _imgSizeExceed: "Taille maximale de 1 Mo dépassée !"
  },
  backgroundImage: {
    _updateDone: "Mise à jour",
    _loadingMessage: "Attendre",
    _imgNotices: "Taille recommandée 600x350 px, max 1 MB",
    _imgSizeExceed: "Taille maximale de 1 Mo dépassée !"
  },
  createSelectEvent: {
    _public: "Public",
    _publicboxtxt: "Faites la promotion de votre événement. Tous vos utilisateurs le verront où qu'ils soient.",
    _private: "Privé",
    _privateboxtxt: "créez votre événement et gérez vos listes d'invités, rapidement et facilement !",
    _notauthorized: "Contactez-nous pour valider cette option."
  },
  description: {
    _descriptionFieldPlaceHolder: "Ajouter une description à votre événement",
    _save: "Sauvegarder",
    _create: "Créer",
    _created: "Créé",
    _modified: "Édité",
    _successmessage: "L'événement a été modifié avec succès",
    _event: "Événement",
    _pagetitlePrivate: "Evénement privé",
    _pagetitlePublic: "Evénement public",
    _eventtype: "Type d'événement *",
    _eventname: "Nom de l'événement *",
    _startdate: "Date de début *",
    _enddate: "Date de fin *",
    _location: "Lieu",
    _flyer: "Dépliant sur l'événement",
    _description: "Description",
    _alternativeLanguageVersion: "Version texte alternative",
    _alternativeLanguageVersionSmall: "Écrivez ici votre texte si votre application d'invité prend en charge un événement multilingue",
    _locationErrorMessage: "Adresse non valable",
    _locationStatusSarching: "Vérification ..",
    _undo: "Celete",
    _clear: "Annuler",
    _next: "Créer",
    _browse: "Choisissez",
    _loadingCoverMessage: "Nous sommes en train de créer l'événement.",
    _creationFailed: "La création a échoué",
    _modificationFailed: "Le changement a échoué ",
    _mandatoryFields: "Les champs marqués d'un astérisque (*) sont obligatoires ",
    _loadingMessage: "Attendre"
  },
  eventList: {
    _archived: "Archivé",
    _unarchive: "UnArchive",
    _private: "Privé",
    _public: "Public",
    _what: "Que voulez-vous faire maintenant ?",
    _publish: "Activez",
    _edit: "Edit",
    _delete: "Supprimer",
    _archive: "Archives",
    _duplicate: "Dupliquer",
    _jan: "Jan",
    _feb: "Février",
    _mar: "Mar",
    _apr: "Avr",
    _may: "Mai",
    _jun: "Jun",
    _jul: "Jul",
    _aug: "Août",
    _sep: "Sep",
    _oct: "Oct",
    _nov: "Nov",
    _dec: "Déc"
  },
  months: [
    "Jan",
    "Février",
    "Mar",
    "Avr",
    "Mai",
    "Jun",
    "Jul",
    "Août",
    "Sep",
    "Oct",
    "Nov",
    "Déc"
  ],
  timeSlotManager: {
    _title: "Créneaux horaires",
    _deleteAllSlots: "Supprimer tous les créneaux",
    _openTableEditor: "Ouvrir l'éditeur",
    _closeTableEditor: "Éliminer les changements",
    _saveTableEditor: "Enregistrer les modifications",
    _deleteAllSlotsWarning: "Voulez-vous vraiment supprimer tous vos créneaux horaires ? Cette action est irréversible.",
    _upload: "Téléchargez votre calendrier (.ics)",
    _innertitle: "Activer les créneaux horaires",
    _maxLimitPerSlot: "Limite par créneau horaire ",
    _import_failure: "Impossible de trouver un créneau horaire admissible à l'importation. Réessayez plus tard",
    _import_success: " Créneau(s) horaire(s) importé(s) avec succès",
    _disabled: "(handicapés)",
    _nothing_to_export: "Aucune donnée à exporter",
    _export_data: "Exportez vos réservations au format .ics",
  },
  eventPad: {
    _save: "Sauvegarder",
    _duplicate: "Dupliquer",
    _create: "Créer",
    _created: "Créé",
    _modified: "Édité",
    _successmessage: "L'événement a été modifié avec succès",
    _privateEvent: "ÉVÉNEMENT PRIVÉ",
    _publicEvent: "ÉVÉNEMENT PUBLIC",
    _event: "événement",
    _pagetitlePrivate: "Evénement privé",
    _pagetitlePublic: "Evénement public",
    _eventtype: "Type d'événement *",
    _eventname: "Nom de l'événement *",
    _startdate: "Date de début *",
    _enddate: "Date de fin *",
    _location: "Lieu",
    _venue: "Lieu :",
    _flyer: "Dépliant sur l'événement",
    _description: "Description",
    _locationErrorMessage: "Adresse non valable",
    _locationStatusSarching: "Vérification",
    _undo: "Supprimer",
    _clear: "Annuler",
    _next: "Créer",
    _browse: "Choisissez",
    _loadingCoverMessage: "Nous sommes en train de créer l'événement.",
    _creationFailed: "La création a échoué",
    _mandatoryFields: "Les champs marqués d'un astérisque (*) sont obligatoires ",
    _loadingMessage: "Attendre",
    _resolvingAddressAlertMessage: "Nous vérifions l'adresse"
  },
  eventPayable: {
    _title: "Evénement payant sur d'autres systèmes",
    _subtitle: "Souligner qu'il s'agit d'un événement payant sur d'autres systèmes",
    _submit: "Sauvegarder",
    _label: "L'événement est-il payant ?",
    _updateDone: "Mise à jour",
    _error: "Erreur non traitée"
  },
  externalReference: {
    _title: "Référence externe",
    _subtitle: "Référence externe Id",
    _placeholder: "Référence externe Id",
    _submit: "Sauvegarder",
    _updateDone: "Mise à jour",
    _error: "Erreur non traitée"
  },
  externalPurchaseEndpoint: {
    _title: "Achat externe",
    _subtitle: "Achat",
    _placeholder: "Achat d'URL",
    _submit: "Sauvegarder",
    _updateDone: "Mise à jour",
    _error: "Erreur non traitée"
  },
  guestlist: {
    _refresh_table: "Rafraîchir la liste des invités",
    _title: "Liste des invités",
    _subtitle: "Ici, vous pouvez gérer votre liste d'invités",
    _loadingMessage: "Assister...",
    _add: "Ajouter manuellement",
    _download: "Liste de téléchargement",
    _upload: "Télécharger la liste",
    _list: "Liste",
    _guestnumber: "Nombre d'invités dans la liste : ",
    _removelist: "Supprimer la liste",
    _nodata: "Pas d'invité",
    _guestadded: "Invité ajouté",
    _listadded: "Liste importée",
    _itemCancellato: "Invité supprimé",
    _listCancellata: "Liste supprimée",
    _downloadlistAll: "Télécharger tous les invités",
    _downloadlist: "Télécharger la liste d'enregistrement",
    _downloadPlusOneList: "Télécharger la liste +1",
    _downloadActivities: "Télécharger le report d'activité",
    _downloadAttachments: "Télécharger les pièces jointes",
    _sure: "En procédant ainsi, vous perdrez irréversiblement toutes les informations de vos invités, y compris les RSVP déjà stockées. Vous êtes sûr de vouloir poursuivre ?",
    _sureForSingleItem: "En procédant, vous perdrez irréversiblement toutes les informations de cet invité. Êtes-vous sûr de vouloir poursuivre ?",
    _guestupdated: "Invité mis à jour",
    _errorInEditItems: "Impossible de poursuivre. Erreurs lors de la mise à jour des informations sur les invités.",
    _cannotProceed: "Impossible de poursuivre",
    _canDeleteGuestWithPurchase: "Impossible de poursuivre. L'invité a un achat actif !",
    _loadInProgress: "Chargement en cours...",
    _attendeePartnersMax: "Partenaires par personne ",
    _attendeePartnersMaxUpdate: "mise à jour effectuée",
    _listEmailsUpdated: "Mise à jour de la liste des courriels",
    _upload_success: "Importer la liste des invités maintenant",
    _upload_failure: "Impossible de télécharger la liste des invités",
    _maxAttendeeExceeded: "Désolé, la liste est complète !",
    _maxGuestSizeReached: "Vous ne pouvez pas importer d'autres invités, vous avez atteint la limite d'invités par événement,",
    _discard_message: "Certains de vos invités n'ont pas été importés avec succès, Cliquez pour voir pourquoi",
    _audience_title: "Filtrer les invités",
    _audience_subtitle: "Sélectionnez l'une des options ci-dessous pour filtrer vos invités",
    _autoPrint: "Badges à impression automatique lors de l'enregistrement",
    _localPrint: "Imprimez automatiquement les badges lors de l'enregistrement manuel en ligne."
  },
  header: {
    _language: "langue",
    _italian: "italien",
    _english: "anglais",
    _francaise : 'française',
    _arab: "arabe",
    _logout: "Déconnexion",
    _unmanagederror: "Une erreur qui n'a pas été corrigée",
    _languagechanged: "Sauvegardé"
  },
  insertTicketForm: {
    _title: "Ajouter un ticket",
    _name: "Description",
    _availability: "Quantité disponible ",
    _save: "Sauvegarder",
    _price: "Prix *",
    _type: "Type de billet *",
    _pay: "Billet payant",
    _donation: "Donation",
    _nolimits: "Aucune limite",
    _mandatoryFields: "Les champs marqués d'un astérisque (*) sont obligatoires "
  },
  listSingleItemPurchaseList: {
    _title: "Insérer les données des participants",
    _name: "Nom *",
    _surname: "Nom de famille",
    _email: "Courrier électronique",
    _tel: "Téléphone",
    _company: "Société",
    _nationality: "Nationalité",
    _job: "Emploi",
    _typology: "Type de client",
    _save: "Enregistrer",
    _loadingMessage: "Attendre...",
    _mandatoryFields: "Les champs avec des étoiles sont obligatoires",
    _unManagedError: "Erreur inattendue",
    _emailAlreadyPresentError: "Le courrier électronique est déjà dans la liste. Opération infructueuse",
    _emailAlreadyPresentErrorToast: "Contact non inséré"
  },
  listSingleItem: {
    _title: "Ajouter un invité",
    _name: "Nom *",
    _surname: "Nom de famille",
    _email: "Courriel",
    _tel: "Téléphone",
    _clear: "Supprimer",
    _save: "Enregistrer",
    _add_n_save: "ajouter et enregistrer",
    _sureRemove: "Vous allez supprimer l'élément sélectionné, êtes-vous sûr?",
    _loadingMessage: "Attendre",
    _mandatoryFields: "Les champs marqués d'un astérisque (*) sont obligatoires",
    _unManagedError: "Erreur non traitée",
    _emailAlreadyPresentError: "Contact non enregistré. Courriel déjà présent",
    _emailAlreadyPresentErrorToast: "contact non enregistré",
    _emailSyntaxError: "L'adresse électronique doit être comme email@example.com",
    _emailSyntaxErrorToast: "Le courrier électronique n'est pas valide"
  },
  listUpload: {
    _title: "Téléchargez une liste d'invités",
    _clear: "Supprimer",
    _load: "Télécharger",
    _save: "Sauvegarder",
    _browse: "Choisissez",
    _loadingMessage: "Attendre",
    _loadingComplete: "Chargée",
    _loadingError: "Le chargement a échoué",
    _itemImported: "Importé",
    _mandatoryFields: "Pas de fichier",
    _unManagedError: "Erreur non traitée",
    _downloadInstruction: "Télécharger le fichier d'importation du modèle",
    _incompleteListUploadWarningTitle: "Importation terminée",
    _incompleteListUploadWarning: "Certains articles n'ont pas été importés."
  },
  messages: {
    _uploadsuccess: "Mise à jour",
    _loadingMessage: "Attendre",
    _inappmessagesimple: "Merci de confirmer",
    _inappmessagemodified: "Merci de confirmer. Ce message est purement informatif. Il n'implique pas l'achat et/ou la confirmation de la réservation d'un accès à un billet.",
    _inmailmessageintent: "Envoyer un e-mail de confirmation une fois que l'utilisateur rsvp/ achat ",
    _savesettings: "Sauvegarder les paramètres",
    _title: "Courriel de confirmation",
    _subtitle: "Définissez et personnalisez l'e-mail que votre utilisateur reçoit après l'achat d'un ticket d'accès",
    _confirmmessagetitle: "Message pour les utilisateurs ",
    _confirmmessagesubtitle: "Définissez un message que vos utilisateurs recevront une fois qu'ils se seront connectés à votre événement ",
    _name: "Nom de l'expéditeur",
    _email: "Courriel de l'expéditeur",
    _customizemsg: "Personnaliser le message",
    _subject: "Sujet",
    _editmsg: "Modifier le message",
    _subjectExplanation: "Modifiez votre modèle pour changer l'objet du courriel de confirmation",
    _registrationFormTitle: "Formulaire d'inscription",
    _registrationFormDescription: "Envoyez un email de confirmation une fois que vos invités se sont inscrits via votre formulaire",
  },
  buttonTabs: {
    INTERNAL_VIRTUAL_ROOM: "activer votre salle Penguinpass",
    INTERNAL_REGISTRATION_FORM: "activer votre salle Penguinpass",
    EXTERNAL_VIRTUAL_ROOM: "activer la salle virtuelle externe",
    createNewTemplate: "créer un nouveau",
    useExistingTemplate: "sélectionnez un modèle",
    CONFIRMATION_EMAIL_ENABLED: "Oui, créez un e-mail de confirmation",
    CONFIRMATION_EMAIL_DISABLED: "Non, ce n'est pas obligatoire",
    SHOW_CATEGORY_FORM:"créer un nouveau",
    SHOW_CATEGORY_TABLE:"gérer les existants",
    SHOW_EVENT_GROUP_FORM:"créer un nouveau",
    SHOW_EVENT_GROUP_TABLE:"gérer les existants",
  },
  virtualRoomSettings: {
    _title: "Salle virtuelle",
    _subtitle: "Activez votre salle virtuelle Penguinpass ou invitez des invités dans d'autres salles virtuelles externes.",
    _externalRoomTitle: "Pièce externe",
    _externalRoomSubtitle: "Ajouter une URL de salle virtuelle personnalisée (Zoom, Microsoft Teams, Google Hangouts, etc.)",
    _internalRoomTitle: "Créez une page de destination pour votre salle virtuelle",
    _internalRoomSubtitle: "",
    _meetingTimeTitle: "Définir l'heure de la réunion",
    _instantMeeting: "Réunion instantanée",
    _meetingTimeSubtitle: "Vous pouvez sélectionner une réunion instantanée pour régler automatiquement l'heure pour le moment",
    _meetingTimeSubtitle2: "Ou régler manuellement l'heure pour plus tard",
    _createdRoomsTitle: "Détails de la salle virtuelle",
    _createdRoomsSubtitle: "",
    _previewTitle: "Aperçu",
    _hour: "heure",
    _hours: "heures",
    _scheduleRoom: "Planifier la salle",
    _moreSettingsTitle: "Plus de paramètres de salle virtuelle",
    _authNeededLabel: "Authentification nécessaire",
    _moderatedChatLabel: "Chat modéré",
    _emailRequiredLabel: "E-mail lors de l'inscription",
    _coverImageLabel: "Télécharger l'image de couverture",
    _authNeededExplanation: "Rendre la page accessible uniquement aux utilisateurs enregistrés.",
    _moderatedChatExplanation: "Cette fonctionnalité permet au modérateur d'approuver ou de refuser tous les messages envoyés par les invités et les présentateurs",
    _emailRequiredExplanation: "Cette fonctionnalité permet d'activer et de désactiver un champ d'e-mail obligatoire dans la page de participation",
    _coverImageExplanation: "Personnalisez votre page de destination avec une image de couverture de votre marque.",
    _uploadImage: "Télécharger l'image (1 MO maximum)",
    _openRoom: "Salle ouverte",
    _createroom: "Créer une nouvelle page de destination",
    _customizeroom: "Personnalisez votre salle virtuelle",
    _authneeded: "Auth required",
    _moderatedchat: "Chat modéré",
    _uploadsuccess: "Mis à jour",
    _loadingMessage: "Attendre",
    _savesettings: "Enregistrer les paramètres",
    _showPreview: "Afficher l'aperçu",
    _hidePreview: "Masquer l'aperçu",
    _selectTemplate: "Appliquer un modèle",
    _enableCustomVRoom: "Activer la salle virtuelle personnalisée",
    _roomscheduling: "Planification des salles",
    _roomschedulingsubtitle: "Ici, vous pouvez planifier le début de votre réunion",
    _instantmeeting: "Réunion instantanée",
    _uploadCover: "Télécharger l'image de couverture",
    _publishpage: "Publier la page",
    _unpublishpage: "Annuler la publication de la page",
    _downloadlogs: "Télécharger les journaux",
    _goToPage: "Ouvrir la page dans un nouvel onglet",
    _pickNewerDate: "La date et l'heure de début doivent être dans le futur",
    _instantMeetingWarning: "Veuillez d'abord arrêter les salles actuellement actives.",
    _pageSlug: "URL personnalisée",
    _applyPageSlug: "Appliquer",
    _pageSlugInUse: "URL personnalisée déjà utilisée",
    _pageSlugExplanation: "Customise your page URL",
    _scheduleRoomHalfAdvance: "Les modérateurs et les présentateurs (webinaire uniquement) pourront s'inscrire une demi-heure à l'avance. Vérifiez l'état de la chambre, elle sera active une demi-heure avant l'heure prévue."
  },
  registrationFormSettings: {
    _title: "Salle virtuelle",
    _subtitle: "Activez votre salle virtuelle Penguinpass ou invitez des invités dans d'autres salles virtuelles externes.",
    _externalRoomTitle: "Pièce externe",
    _externalRoomSubtitle: "Ajouter une URL de salle virtuelle personnalisée (Zoom, Microsoft Teams, Google Hangouts, etc.)",
    _internalRoomTitle: "Créez une page de destination pour votre salle virtuelle",
    _internalRoomSubtitle: "",
    _meetingTimeTitle: "Définir l'heure de la réunion",
    _instantMeeting: "Réunion instantanée",
    _meetingTimeSubtitle: "Vous pouvez sélectionner une réunion instantanée pour régler automatiquement l'heure pour le moment",
    _meetingTimeSubtitle2: "Ou régler manuellement l'heure pour plus tard",
    _createdRoomsTitle: "Détails de la salle virtuelle",
    _createdRoomsSubtitle: "",
    _previewTitle: "Aperçu",
    _hour: "heure",
    _hours: "heures",
    _scheduleRoom: "Planifier la salle",
    _moreSettingsTitle: "Plus de paramètres de salle virtuelle",
    _authNeededLabel: "Authentification nécessaire",
    _moderatedChatLabel: "Chat modéré",
    _emailRequiredLabel: "E-mail lors de l'inscription",
    _coverImageLabel: "Télécharger l'image de couverture",
    _authNeededExplanation: "Rendre la page accessible uniquement aux utilisateurs enregistrés.",
    _moderatedChatExplanation: "Cette fonctionnalité permet au modérateur d'approuver ou de refuser tous les messages envoyés par les invités et les présentateurs",
    _emailRequiredExplanation: "Cette fonctionnalité permet d'activer et de désactiver un champ d'e-mail obligatoire dans la page de participation",
    _coverImageExplanation: "Personnalisez votre page de destination avec une image de couverture de votre marque.",
    _uploadImage: "Télécharger l'image (1 MO maximum)",
    _openRoom: "Salle ouverte",
    _createroom: "Créer une nouvelle page de destination",
    _customizeroom: "Personnalisez votre salle virtuelle",
    _authneeded: "Auth required",
    _moderatedchat: "Chat modéré",
    _uploadsuccess: "Mis à jour",
    _loadingMessage: "Attendre",
    _savesettings: "Enregistrer les paramètres",
    _showPreview: "Afficher l'aperçu",
    _hidePreview: "Masquer l'aperçu",
    _selectTemplate: "Appliquer un modèle",
    _enableCustomVRoom: "Activer la salle virtuelle personnalisée",
    _roomscheduling: "Planification des salles",
    _roomschedulingsubtitle: "Ici, vous pouvez planifier le début de votre réunion",
    _instantmeeting: "Réunion instantanée",
    _uploadCover: "Télécharger l'image de couverture",
    _publishpage: "Publier la page",
    _unpublishpage: "Annuler la publication de la page",
    _downloadlogs: "Télécharger les journaux",
    _goToPage: "Ouvrir la page dans un nouvel onglet",
    _pickNewerDate: "La date et l'heure de début doivent être dans le futur",
    _instantMeetingWarning: "Veuillez d'abord arrêter les salles actuellement actives.",
    _pageSlug: "URL personnalisée",
    _applyPageSlug: "Appliquer",
    _pageSlugInUse: "URL personnalisée déjà utilisée",
    _pageSlugExplanation: "Customise your page URL",
    _scheduleRoomHalfAdvance: "Les modérateurs et les présentateurs (webinaire uniquement) pourront s'inscrire une demi-heure à l'avance. Vérifiez l'état de la chambre, elle sera active une demi-heure avant l'heure prévue."
  },
  modalEmailBuilder: {
    _title: "Quelque chose ne va pas...",
    _buttonClose: "Fermer",
    _unManagedError: "Erreur inattendue",
    _templateCreationError: "Erreur : le modèle n'a pas été créé",
    _templateSaved: "Modèle sauvegardé",
    _emailCreationError: "Erreur : le modèle n'a pas été créé",
    _emailSaved: "Courriel enregistré",
    _save: "Sauvegarder",
    _confirm: "Confirmer",
    _update: "Mise à jour",
    _cancel: "Annuler",
    _templateName: "Nom du modèle",
    _templateSubject: "Objet du courrier électronique",
    _backToTemplates: "Retour aux modèles",
    _nameIsMandatory: "Le nom du modèle et le sujet sont obligatoires",
    _areyousure: "Êtes-vous sûr",
    _areyousure_cloning: "Voulez-vous vraiment créer un nouveau modèle en utilisant celui-ci ?",
    _areyousure_discard: "Voulez-vous vraiment sortir avant de sauvegarder vos modifications ?",
    _notemplatesave: "Modèle non salvato",
    _templateDelete: "Modèle supprimé",
    _notemplate: "Pas de modèle stocké",
    _create: "Créer",
    _template_sent: "Courriel envoyé !",
    _template_sent_error: "Le courriel n'a pas pu être envoyé avec succès !",
    _use_save_button: "Veuillez utiliser le bouton de sauvegarde à droite à la place"
  },
  newEvent: {
    _create: "Créer",
    _created: "Créé",
    _event: "Événement",
    _pagetitlePrivate: "Evénement privé",
    _pagetitlePublic: "Evénement public",
    _eventtype: "Type d'événement *",
    _eventname: "Nom de l'événement *",
    _startdate: "Date de début *",
    _enddate: "Date de fin *",
    _location: "Adresse *",
    _venue: "Lieu :",
    _virtualVenue: "Lieu virtuel",
    _descriptionIt: "Description (It)",
    _descriptionEn: "Description (En)",
    _undo: "Supprimer",
    _clear: "Annuler",
    _next: "Créer",
    _loadingCoverMessage: "Nous créons l'événement...",
    _creationFailed: "La création a échoué",
    _mandatoryFields: "Les champs marqués d'un astérisque (*) sont obligatoires",
    _placeNotValid: "Le lieu n'est pas valable"
  },
  overview: {
    _pending: "En attente",
    _confirmed: "Confirmé",
    _checkin: "Checked-in",
    _prediction: "Prediction",
    _total: "Tous les invités",
    _guestsAndCompanions: "Confirmé & +1"
  },
  promoteEvent: {
    _title: "Promotion d'événements",
    _subtitle: "Faites la promotion de votre événement. Faites en sorte qu'il soit le premier à être affiché sur l'application",
    _submit: "Sauvegarder",
    _label: "Promouvoir cet événement",
    _updateDone: "Mise à jour",
    _error: "Erreur non traitée"
  },
  purchaselist: {
    _title: "Liste RSVP",
    _subtitle: "Ici vous pouvez vérifier les utilisateurs qui ont un rsvp pour votre événement ",
    _loadingMessage: "Attends...",
    _add: "Ajouter manuellement",
    _download: "Liste de téléchargement",
    _upload: "Télécharger la liste",
    _list: "Liste",
    _guestnumber: "Nombre de RSVP : ",
    _removelist: "Supprimer la liste des invités",
    _nodata: "Pas de RSVP",
    _guestadded: "Invité ajouté",
    _listadded: "Liste importée",
    _itemCancellato: "Invité supprimé",
    _listCancellata: "Liste supprimée",
    _downloadlistAll: "Télécharger toute la liste",
    _downloadlist: "Télécharger la liste d'enregistrement uniquement"
  },
  report: {
    _title: "Rapport statistique ",
    _subtitle: "Analyse des données collectées ",
    _timeLineCheckinChartLegend:"Le graphique montre le développement et le débit horaire au point d'enregistrement pendant l'événement.",
    _attendeeConfirmationChartLegend: "Le graphique montre le nombre rsvp quotidien",
    _export: "Télécharger",
    _attendeeChart: "Liste des participants",
    _timeLineAttendeeConfirmationTitle: "Calendrier de confirmation des participants",
    _timeLineCheckInTitle: "Chronologie de l'enregistrement",
    _totalAttendeeStatus: "Nombre total de participants",
    _export_request_received: "Votre demande est prise en compte. Nous vous informerons prochainement",
    _export_request_finished: "Votre rapport est prêt. Cliquez ici pour le télécharger",
    _export_failed: "L'opération a échoué",
    _export_empty: "Le rapport que vous essayez de télécharger est vide"
  },
  sidebar: {
    _dashboard: "Tableau de bord",
    _newevent: "Nouvel événement",
    _downloadpenguinpass: "App. invité",
    _privacypolicy: "Politique de confidentialité",
    _terms: "Conditions générales",
    _linkTerms: "https://www.penguinpass.it/terms-and-conditions/",
    _contacts: "Contactez",
    _faq: "/help/fr/index.html",
    _allevents: "Événements",
    _eventArchived: "Archives",
    _linkContacts: "https://www.penguinpass.it/contact-us.html",
    _privacypolicyURL: "https://www.penguinpass.it/privacypolicyEN.html",
    _applestore: "https://itunes.apple.com/it/app/penguinpass/id1050702093?mt=8",
    _googlestore: "https://play.google.com/store/apps/details?id=com.penguinpass.checkinlight",
    _downloadpenguinpasscheckinURL: "https://play.google.com/store/apps/details?id=com.penguinpass.checkinlight&hl=fr",
    _downloadpenguinpasscheckinURL_android:"https://play.google.com/store/apps/details?id=com.penguinpass.checkinlight&hl=fr",
    _downloadpenguinpasscheckinURL_ios:"https://apps.apple.com/fr/app/penguin-check-in-light/id1572985276",
    _downloadpenguinpasscheckin_android: "Check-In App Android",
    _downloadpenguinpasscheckin_ios: "Check-In App IOS",
    _downloadpenguinpassbeacon: "App. balise",
    _downloadpenguinpassbeaconURL: "https://itunes.apple.com/it/app/vbpenguinpass/id1204226558?mt=8",
    _members: "Mon équipe",
    _settings: "Paramètres",
    _logoutConfirm: "Voulez-vous vraiment vous déconnecter?",
    _eventGroup: "Groupes d'événements",
  },
  tickets: {
    _title: "Billets",
    _subtitle: "Gérer la liste des billets de votre événement",
    _nodata: "Evénement gratuit",
    _nodata_payable: "Evénement payant sur d'autres systèmes",
    _add: "Ajouter un ticket",
    _confirmDelete: "Êtes-vous sûr de vouloir supprimer ?",
    _itemCancellato: "Titre supprimé",
    _itemAdded: "Titre ajouté"
  },
  urlPrivacyPolicy: {
    _title: "Politique de confidentialité",
    _management: "Politique de confidentialité - gestion des événements",
    _subtitle: "Achat",
    _placeholder: "Politique en matière d'URL",
    _submit: "Sauvegarder",
    _updateDone: "Mise à jour",
    _error: "Erreur non traitée",
    _sure: "En procédant ainsi, vous perdrez irréversiblement toutes les informations de cette politique de confidentialité. Êtes-vous sûr de vouloir poursuivre ?"
  },
  visibilityManager: {
    _publish: "Activez l'événement",
    _hidden: "Désactiver l'événement",
    _uploadsuccess: "Mise à jour",
    _archivesuccess: "Événement archivé",
    _unarchivesuccess: "Événement non archivé",
    _error: "Erreur : remplissez les champs obligatoires"
  },
  main: {
    _notauthorizedoption: "Option non valable"
  },
  home: {
    _titleEventList: "Événements actifs"
  },
  eventCreateList: {
    _event: "Événement",
    _createString: "Créez votre première liste d'invités",
    _prospectList: "Liste des invités",
    _add: "Ajouter manuellement",
    _download: "Liste de téléchargement",
    _upload: "Télécharger la liste",
    _next: "Suivant",
    _loadingMessage: "Attends...",
    _sure: "Êtes-vous sûr de vouloir continuer ?",
    _itemCancellato: "Invité supprimé"
  },
  events: {
    _noEvents: "Pas d'événement.",
    _progress: "Chargement...",
    _all_events: "Tous les événements",
    _my_events: "Mes billets",
    _favourites: "Favoris",
    _archived_events: "Événements archivés",
    _search_result: "Résultats de la recherche"
  },
  singleEvent: {
    _event: "Événement",
    _createString: "Créez votre première liste d'invités",
    _prospectList: "Liste des invités",
    _add: "Ajouter manuellement",
    _download: "Liste de téléchargement",
    _upload: "Télécharger la liste",
    _next: "Suivant",
    _insertPlace: "Ajouter un lieu",
    _loadingMessage: "Attends...",
    _description: "Description",
    _emailMarketingWarning: "Activez votre événement pour utiliser la fonction de marketing par courrier électronique"
  },
  datagrid: {
    _pending: "En attente",
    _confirmed: "Confirmé",
    _checkin: "Check-In",
    _declined: "Déclin",
    _will_be_back: "Reviendra",
    _checkout: "Check-out",
    _search: "Recherchez",
    _selected: "Invité sélectionné",
    _selectAllGuests: "Tous",
    _selectAllMarketingGuests: "Tous les invités éligibles",
    _selectPending: "Invités en attente",
    _selectDecline: "Invités déclinés",
    _selectNotRead: "Invités qui n'ont pas lu les campagnes précédentes",
    _selectNotSend: "Invités non inclus dans les campagnes précédentes",
    _selectNotSendConfirmed: "Invités confirmé et non inclus dans les campagnes précédentes",
    _selectUnRead: "Sélectionnez uniquement les invités non lus",
    _selectUnSent: "Sélectionnez uniquement les invités non envoyés",
    _selectConfirmed: "Invités confirmés",
    _selectCheckedIn: "Invités enregistrés",
    _selectCustom: "Sélectionnez Personnalisé dans la liste",
    _selectCustomField: "Utiliser les champs personnalisés",
    _selectTimeSlot: "Utiliser les créneaux horaires",
    _edit_emails: "Modifier les courriers électroniques",
    _list_emails: "Voir la liste",
    _list_events: "Voir la liste",
    _post_confirmation_blocked: "Désolé, l'invité a déjà été confirmé. Certaines informations ne peuvent pas être modifiées",
    _guest_name: "Nom",
    _guest_surname: "Nom de famille"
  },
  emailBuilderSelectMode: {
    _selectTemplateTitle: "Modèle",
    _selectTemplateText: "Commencer à éditer un message à partir d'un modèle",
    _new: "Nouveau",
    _newText: "Créer un nouveau message à partir de zéro"
  },
  directEmailMarketing: {
    _updatingCampaignStats: "mise à jour des statistiques de la campagne",
    _refreshStats: "Rafraîchir les statistiques",
    _title: "Campagne par courrier électronique",
    _subtitle: "Vous pouvez créer des e-mails pour inviter des invités à votre événement, ainsi que recevoir des résumés pour gérer vos e-mails.",
    _warnBeforeDelete: "Voulez-vous vraiment écarter les changements et supprimer votre campagne ?",
    _warnBeforeSend: "Êtes-vous sûr de vouloir envoyer votre campagne ?",
    _deleteCampaign: "Supprimer la campagne",
    _recap: "Récapitulatif de vos campagnes de courrier électronique",
    _recapsubtitle: "Gardez une trace des mails envoyés, en restant à jour sur le statut",
    _name: "Nom (expéditeur)",
    _email: "Courriel (expéditeur)",
    _subject: "Sujet",
    _send: "envoyer une campagne",
    _save_changes: "enregistrer les changements",
    _startfromtemplate: "choisir un modèle",
    _nocampaign: "pas de courrier électronique envoyé",
    _message: "Message",
    _contacts: "Contacts",
    _logo: "Logo",
    _flyer: "Flyer",
    _clear: "clair",
    _browse: "broser",
    _undo: "clair",
    _join: "S'inscrire par courrier électronique",
    _join_explain: "En vérifiant le champ, vos invités pourront confirmer leur présence par e-mail",
    _setpartnermax: "Champ de confirmation +1",
    _setpartnermax_explain: "En sélectionnant le champ, les invités peuvent indiquer le nombre de leurs connaissances, toujours dans la limite maximale fixée par vous. Veuillez noter que si l'invité a déjà indiqué le nombre de ses connaissances, il est conseillé de ne pas modifier les données affichées dans la section liste.",
    _setnote: "Demande de nom +1",
    _setnote_explain: "En sélectionnant le champ, vos invités peuvent entrer les noms de leurs connaissances ou des demandes spéciales. Vous y trouverez dans le champ notes du fichier excel de l'exportation que vous pouvez faire à tout moment à partir du tableau de bord.",
    _imgNoticesLogo: "Extensions autorisées .jpg/.png, max 1 MB",
    _imgNoticesFlyer: "Extensions autorisées .jpg/.png, max 1 MB",
    _create: "Campagne Create/Edit",
    _personalizedLabel: "Personnaliser",
    _personalizedExplain: "Personnalisez le message en ajoutant le nom de votre destinataire",
    _createEmail: "Créer un courriel",
    _modifyEmail: "Modifier le courrier électronique",
    _username: "nom d'utilisateur",
    _senderName: "Nom",
    _save: "Enregistrer",
    _modify: "Modifier",
    _selectOneVerifiedEmail: "Sélectionnez une adresse e-mail vérifiée",
    _selectOneVerifiedDomain: "Sélectionnez une adresse de domaine vérifiée",
    _defaultEmailExplanation1: "Pour modifier l'adresse e-mail, vous devez d'abord vérifier votre nom de domaine ou votre adresse e-mail.",
    _goToProfileSettings: "Accédez aux paramètres du profil",
    _defaultEmailExplanation2: "pour modifier l'adresse e-mail",
    _editSettings: "Modifier les paramètres",
    _subjectExplanation: "Modifiez votre modèle pour changer le sujet de la campagne",
    _campaign_sent: "La demande a été acceptée. Nous traitons maintenant votre campagne",
    _recap_name: "Nom : ",
    _recap_email: "Adresse électronique : ",
    _recap_subject: "Sujet : ",
    _recap_total_recipient: "Bénéficiaire(s) total(s) : ",
    _warning_email: "Veuillez d'abord sélectionner une adresse électronique vérifiée ",
    _warning_email_2: "Veuillez d'abord vérifier une adresse électronique",
    _warning_domain: "Veuillez d'abord vérifier et/ou sélectionner un domaine et un nom d'utilisateur",
    _warning_default_email: "Impossible d'envoyer la campagne car l'adresse électronique n'est pas valide",
    _warning_recipient: "Veuillez sélectionner un ou plusieurs destinataires",
    _warning_template: "Veuillez d'abord sélectionner ou créer un modèle de courriel",
    _sending_campaign: "L'envoi...",
    _cancel_campaign: "Annuler",
    _audience_title: "Public cible",
    _audience_subtitle: "Filtrez la liste de votre campagne d'e-mails.",
    _emailBuilderTitle: "Créer un e-mail",
    _emailBuilderSubtitle: "Utilisez le générateur pour créer votre propre design.",
    _additionalSettingsTitle: "Plus de paramètres de messagerie",
    _customFields_title: "Champs personnalisés",
    _customPolicy_title: "Politique de confidentialité",
    _customFields_explain1: "Pour obtenir plus d'informations de vos invités, vous pouvez ajouter des champs personnalisés.",
    _customFields_explain2: "pour gérer les champs personnalisés",
    _customPolicy_explain1: "Définissez la politique de confidentialité pour recevoir les informations de vos invités.",
    _customPolicy_explain2: "pour gérer les politiques de confidentialité.",
    _goToSettings: "Accéder aux paramètres",
    _confirmationEmail_title: "E-mail de confirmation",
    _confirmationEmail_subtitle: "Une fois que l'invité a confirmé qu'il assisterait à l'événement ou qu'il a répondu, vous pouvez lui envoyer un e-mail de confirmation pour vous assurer que sa réponse a bien été acceptée.",
    _noCampaigns: "Aucun e-mail n'a encore été envoyé"
  },
  customFieldFilter: {
    _custom_field_filter_header: "Filtrez",
    _custom_field_filter_question_choose: "Sélectionnez l'un des champs que vous souhaitez utiliser comme filtre",
    _custom_field_filter_answer_choose: "Sélectionnez l'une des réponses (facultatif)",
    _custom_field_filter_answer_input: "Tapez la réponse que vous recherchez (facultatif)",
    _custom_field_filter_not_valid: "Les filtres ne sont pas valables. Veuillez sélectionner un champ et une réponse"
  },
  searchEvent: {
    _eventType: "Type d'événement",
    _eventName: "Nom de l'événement",
    _search: "Recherchez",
    _description: "Description",
    _address: "Adresse",
    _sDate: "Date de début",
    _eDate: "Date de fin"
  },
  categoryManager: {
    _title: "Changer de catégorie d'événement",
    _subtitle: "",
    _eventType: "catégorie",
    _submit: "Sauvegarder"
  },
  customFieldsManager: {
    _selectMax: "Max Options sélectionnables",
    _insertAnswers: "Insérer des réponses",
    _title: "Modifier les champs personnalisés",
    _subtitle: "Gestion des champs personnalisés",
    _submit: "Sauvegarder",
    _noCustomFields: "Pas d'ensemble de champs personnalisés",
    _name: "Nom : ",
    _label: "Nom du champ personnalisé",
    _answer: "Réponse ",
    _create: "Créer",
    _sure: "En procédant ainsi, vous perdrez irréversiblement toutes les informations de ce champ personnalisé. Êtes-vous sûr de vouloir poursuivre ?",
    _createNew: "CRÉER UN NOUVEAU CHAMP PERSONNALISÉ",
    _chooseExisting: "CRÉATION DE CHAMPS PERSONNALISÉS",
    _updateDone: "Mise à jour",
    _error: "erreur non traitée",
    _alreadyExists: "Champ personnalisé déjà existant",
    _checkInApp: "Montrer sur Application d'enregistrement",
    _RSVP: "Montrer sur RSVP",
    _registrationFormPage: "Montrer sur le Formulaire d'inscription",
    _hideStaff: "Masquer pour le personnel",
    _validateField: "Valider le champ",
    _mandatory: "Obligatoire",
    OPEN_ANSWER: "Réponse ouverte",
    MULTIPLE_CHOICE: "Choix multiple",
    TEXT_ONLY: "Texte",
    MATRICOLA: "Numéro d'enregistrement",
    _maxSelection: "Réponses maximales sélectionnables",
    _type: "Tapez",
    _limitReached: "Limite atteinte pour la réponse sélectionnée.",
    _validationWithInvalid: "Valeur incorrecte fournie pour le champ.",
    _fieldName: "Champ",
    _questionTitle: "Question",
    _answerTitle: "Réponse",
    _limitAction: "Veuillez modifier votre réponse ou rafraîchir la page",
    _add_csv: "Tapez ici vos réponses en les séparant par une virgule (ex. réponse 1,réponse 2,réponse 3)",
    _confirm_disabling_option: "Certains de vos invités ont déjà choisi cette réponse. Si vous continuez, la réponse ne sera plus sélectionnable, les invités qui ont déjà choisi cette réponse ne seront pas affectés. Souhaitez-vous poursuivre ?"
  },
  teamCustomFieldsManager: {
    _selectMax: " options sélectionnables maximum ",
    _insertAnswers: "Insérer des réponses",
    _title: "Gérer les champs d'informations personnelles des invités",
    _subtitle: "Gestion des champs d'informations personnelles",
    _submit: "Enregistrer",
    _noCustomFields: "Aucun champ d'informations personnelles défini",
    _name: "nom: ",
    _label: "Nom du champ d'informations personnelles",
    _answer: "Répondre",
    _create: "Créer",
    _sure:"En procédant, vous perdrez de manière irréversible toutes les informations de ce champ d'informations personnelles. Êtes-vous sûr de vouloir précéder ?",
    _createNew: "CRÉER UN NOUVEAU CHAMP D'INFORMATIONS PERSONNELLES",
    _chooseExisting: "CRÉATION DE CHAMPS D'INFORMATIONS PERSONNELLES",
    _updateDone: "Mise à jour",
    _error: "erreur non gérée",
    _alreadyExists: "Champ d'informations personnelles déjà existant",
    _checkInApp: "Afficher sur l'application d'enregistrement",
    _RSVP: "Afficher sur RSVP",
    _landingPage: "Afficher à l'atterrissage",
    _hideStaff: "Masquer pour le personnel",
    _mandatory: "Obligatoire",
    OPEN_ANSWER: "Réponse ouverte",
    MULTIPLE_CHOICE: "Choix multiple",
    _maxSelection: "Maximum de réponses sélectionnables",
    _type: "Tapez",
    _limitReached: "Limite atteinte pour la réponse sélectionnée.",
    _questionTitle: "Question",
    _answerTitle: "Répondre",
    _limitAction: "Veuillez modifier votre réponse ou actualiser la page",
    _add_csv: "Tapez ici vos réponses en les séparant par une virgule (ex. réponse 1,réponse 2,réponse 3)",
    _selectTimeSlotWarning: "Veuillez sélectionner au moins un créneau horaire",
    _confirm_disabling_option: "Certains de vos invités ont déjà sélectionné cette réponse. Si vous continuez, la réponse ne sera plus sélectionnable, les invités qui ont déjà sélectionné cette réponse ne seront pas affectés. Souhaitez-vous continuer ?"
  },
  loginPage: {
    _login_title: "Bienvenue !",
    _login_description: " ",
    _email_hint: "Adresse électronique",
    _password_hint: "Mot de passe",
    _click_here: "Cliquez ici",
    _password_recovery: "Mot de passe oublié ?",
    _submit: "Entrez",
    _remember_me: "Se souvenir de moi",
    _password_mandatory: "Le mot de passe est obligatoire",
    _email_not_valid: "L'adresse électronique n'est pas valide ",
    _sso: "SSO",
  },
  registerPage: {
    _register_title: "Se connecter",
    _register_title_success: "Bienvenue !",
    _register_description: "Inscrivez-vous GRATUITEMENT pour commencer à essayer Penguinpass et écrivez-nous si vous avez besoin d'informations ou de demandes spéciales pour votre événement. Nous serons heureux de vous expliquer à quel point notre système est révolutionnaire.",
    _email_hint: "Adresse électronique",
    _password_hint: "Mot de passe",
    _click_here: "Cliquez ici",
    _password_recovery: "Mot de passe oublié ?",
    _submit: "Soumettre",
    _remember_me: "Se souvenir de moi",
    _privacy_policy_mandatory: "Vous devez accepter notre politique de confidentialité et les conditions d'enregistrement",
    _email_not_valid: "L'adresse électronique n'est pas valide ",
    _name_surname_mandatory: "Le nom et le prénom sont obligatoires ",
    _company_mandatory: "Le nom de la société est obligatoire",
    _password_mandatory: "Le mot de passe est obligatoire",
    _passwords_not_match: "Les mots de passe ne sont pas les mêmes",
    _p_name: "Nom",
    _p_surname: "Nom* de famille",
    _p_email: "Courrier électronique",
    _p_company: "Nom de la société*",
    _p_vat_number: "Numéro de TVA",
    _p_site: "Site web",
    _p_city: "Ville",
    _p_nation: "Nationalité",
    _p_password: "Mot de passe*",
    _p_password_confirm: "Confirmation du mot de passe*.",
    _p_description: "Si vous voulez, entrez un message pour nous",
    _after_register_title: "Prima di cominciare abbiamo bisogno di verificare la tua mail.",
    _after_register_description_1: "Notre équipe se tient à votre disposition pour vous rendre compte de l'état de votre compte, de la qualité normale de l'environnement et de la configuration du système pour votre propre compte.",
    _after_register_description_2: " Vous pouvez communiquer avec nos services"
  },
  organizerSettings: {
    _pageTitle: "Paramètres",
    _tabProfile: "Profil",
    _tabPayment: "Paiements",
    _tabPassword: "Mot de passe",
    _tabEventSettings: "Paramètres d'événement",
    _tabCustomCategories: "Catégories",
    _tabDomain: "Domaine",
    _tabUnsubscribers: "Désabonnés",
    _profileHeader: "Informations sur le registre",
    _profileSubHeader: "Résumé des données personnelles",
    _profilePersonalSection: "Informations personnelles",
    _profileCompanySection: "Informations sur la société",
    _profileBackgroundSection: "Arrière-Plan",
    _placeholderName: "Nom",
    _placeholderSurname: "Nom de famille",
    _placeholderCompanyName: "Société",
    _placeholderVatNumber: "Numéro de TVA",
    _placeholderCity: "Ville",
    _modify: "Modifier",
    _submit: "Soumettre",
    _add_new_email: "Vérifier l'adresse électronique",
    _add_new_domain: "Vérifier le domaine",
    _passwordHeader: "Modifiez votre mot de passe ",
    _domainHeader: "Vérification de l'identité",
    _select_identity_method: "Sélectionnez votre méthode d'identification",
    _newDomainSection: "Nom de domaine à vérifier (exemple .com)",
    _newEmailSection: "Adresse électronique à vérifier (name@example.com)",
    _email: "Courriel",
    _domain: "Domaine",
    _placeholderEmail: "Adresse électronique à vérifier (name@example.com)",
    _placeholderDomain: "Nom de domaine à vérifier (exemple .com)",
    _selectIdentityType: "Sélectionnez",
    _default: "Défaut (pas de vérification)",
    _email_recap: "Identités de courrier électronique",
    _domain_recap: "Domaines personnalisés",
    _recap: "Identités de domaine",
    _recapSubtitle: "Ici, vous pouvez vérifier vos adresses électroniques ou vos domaines personnalisés",
    _email_recap_subtitle: "Ici, vous pouvez vérifier le statut de vos adresses électroniques utilisées dans le cadre du marketing par courrier électronique",
    _mail_from_desc_1: "La configuration personnalisée de MAIL FROM peut vous aider à améliorer votre réputation et votre image de marque en matière de livraison de courrier électronique. Vous ne devez activer et configurer que si votre fournisseur DNS vous permet de modifier les valeurs MX pour un sous-domaine personnalisé (par exemple aruba.it ne vous permet pas de les modifier)",
    _mail_from_desc_2: "- La valeur MX est pour l'envoi de courriels en utilisant votre domaine personnalisé. ",
    _mail_from_desc_3: "- La valeur TXT correspond à la configuration du Sender Policy Framework (SPF).",
    _domain_desc_1: "Penguinpass utilise Amazon Web Services (AWS), l'une des solutions les plus fiables du marché, pour envoyer des courriers électroniques. Afin d'envoyer des courriels au nom de votre domaine personnalisé ;",
    _domain_desc_2: "- La valeur TXT correspond à l'autorisation d'envoyer des courriels au nom de votre domaine.",
    _domain_desc_3: "- Les valeurs CNAME correspondent aux paramètres DKIM de votre domaine.",
    _domain_desc_4: "Avec ces deux configurations, vous serez en mesure d'envoyer des courriers électroniques en utilisant le serveur de courrier électronique Penguinpass sans perdre la capacité d'envoyer des courriers électroniques avec votre serveur de courrier électronique d'origine.",
    _domain_desc_5: "Toutes les réponses aux courriers électroniques envoyés retourneront toujours à votre serveur de courrier électronique d'origine. Pour réduire le taux de spam, les valeurs CNAME sont également marquées comme obligatoires.",
    _domain_desc_6: "Après vérification de votre domaine, vous pourrez utiliser n'importe quel nom d'utilisateur sous votre domaine comme expéditeur de courrier électronique, par exemple `any_username@yourdomain.com`.",
    _domain_desc_7: "Votre demande de vérification expirera après 48 heures. Dans ce cas, vous devez soumettre une nouvelle demande de vérification.",
    _read_more: "Pour en savoir plus",
    _read_less: "montrer moins",
    _step: "Étape",
    _step_1: "Création d'un domaine",
    _step_2: "Modifier la configuration du DNS",
    _step_3: "Temps d'attente jusqu'à 48h après la modification de la configuration du DNS",
    _refreshIdentities: "Cliquez pour actualiser",
    _updatingIdentities: "Rafraîchissement",
    _no_data: "Pas encore de données",
    _settingsUpdated: "Paramètres mis à jour !",
    _profileUpdated: "Informations sur le profil mises à jour !",
    _password_empty: "Le mot de passe est vide !",
    _newPassword_warning_message: "Les mots de passe ne sont pas les mêmes",
    _password_updated: "Mot de passe mis à jour !",
    _same_password: "Veuillez choisir un mot de passe différent du précédent",
    _email_exists: "Le courrier électronique est déjà présent !",
    _unsubscribersHeader: "Désabonnés",
    _recap_unsubscribers: "Désabonnés",
    _recapSubtitle_unsubscribers: "Vous pouvez consulter ici les adresses électroniques qui ont été désabonnées de votre équipe",
    _unsubscriber_number: "Nombre de désabonnés : ",
    _download_unsubscribers: "Télécharger toute la liste",
    _remove_existing: "Supprimez d'abord vos identités existantes",
    _download_record_set: "Télécharger le record de téléchargement sous XLSX",
    _deleteDomain: "Supprimer le domaine",
    _warn_before_delete_domain: "Vous voulez vraiment supprimer votre domaine ?",
    _domain_exists: "Ce domaine a déjà été vérifié par un autre utilisateur. Veuillez nous contacter si vous êtes le propriétaire de ce domaine",
    _email_verification_exists: "Ce courriel a déjà été vérifié par un autre utilisateur. Veuillez nous contacter si vous êtes le propriétaire de cet e-mail",
    _email_syntax_error: "L'adresse électronique n'est pas valide",
    _domain_syntax_error: "Le domaine n'est pas valide",
    _email_domain_verification_exists: "Le domaine de ce courriel a déjà été vérifié par un autre utilisateur. Veuillez nous contacter si vous êtes le propriétaire de ce domaine.",
    _eventSettingsHeader: "Gérer les paramètres d'événement",
    _eventSettingsSubtitle: "Vous pouvez gérer ici les informations personnelles à demander à vos invités lorsqu'ils s'inscrivent ou se joignent à vos événements.",
    _customCategoriesHeader: "Gérez vos catégories personnalisées",
    _customCategoriesSubtitle: "Ici, vous pouvez gérer vos catégories personnalisées pour regrouper vos événements comme vous le souhaitez.",
    _eventGroupTitle: "Gérez vos groupes d'événements",
    _eventGroupSubtitle: "Ici, vous pouvez gérer vos groupes d'événements pour contrôler votre gestion des accès à un ou plusieurs événements."
   },
  recoveryPassword: {
    _send_link: "Soumettre",
    _email_label: "Entrez votre email",
    _resend_success_title: "Succès!",
    _resend_success_subtitle: "Vérifiez votre boîte de réception. Espérons que ce sera la dernière fois!",
    _verification_success_title: "Succès!",
    _verification_success_guest: "Vérification terminée! Vous pouvez revenir à votre application et vous connecter",
    _verification_success_organizer: "Vérification terminée! Vous pouvez accéder à la page de connexion et utiliser le tableau de bord",
    _verification_failed_title: "Échec!",
    _verification_failed: "La vérification a échoué",
    _verification_expired: "Le lien de vérification a expiré. Si vous souhaitez effectuer une nouvelle vérification, veuillez saisir votre adresse e-mail",
  },
  verifyAccount: {
    _send_link: "Submit",
    _email_label: "Enter your email",
    _resend_success_title: "Success!",
    _resend_success_subtitle: "Check your inbox. Hopefully, it will be last time!",
    _verification_success_title: "Success!",
    _verification_success_guest: "Verification complate! You can return to your application and login",
    _verification_success_organizer: "Verification complate! You can go to login page and use dashboard",
    _verification_failed_title: "Failed!",
    _verification_failed: "Verification failed",
    _verification_expired: "Verification link is expired. If you want to verify again, please type your email",
    joinOk: "Nous confirmons également que votre inscription à l'événement a réussi !",
    joinErr: "Nous sommes désolés, l'inscription à l'événement a échoué, veuillez vous connecter et réessayer."
  },
  termsAndConditions: {
    _terms_accept: "et de convenir de la",
    _privacy_accept: "J'ai lu",
    _privacy_title: "la déclaration de confidentialité",
    _terms_title: "conditions générales de service",
    _mandatory: "(obligatoire)",
    _updates: "J'autorise l'utilisation de mes données personnelles pour rester informé des mises à jour du produit",
    _marketing: "J'autorise le traitement de mes données personnelles à des fins de marketing, y compris celles de tiers"
  },
  eventCategories: {
    _contentEvent: "CONTENU DE L'ÉVÉNEMENT",
    _pressDay: "JOURNÉE DE PRESSE",
    _fashionShow: "SALON DE LA MODE",
    _corporateEvent: "ÉVÉNEMENT D'ENTREPRISE",
    _fashionEvent: "ÉVÉNEMENT DE MODE",
    _onInvitation: "SUR INVITATION",
    _cocktailParty: "SOIRÉE COCKTAIL"
  },
  eventCategoriesAsOnDB: {
    _contentEvent: "CONTENT_EVENEMENT",
    _pressDay: "PRESSE_DAY",
    _fashionShow: "SALON_DE_LA_MODE",
    _corporateEvent: "CORPORATE_EVENT",
    _fashionEvent: "FASHION_EVENT",
    _onInvitation: "SUR_INVITATION",
    _cocktailParty: "COCKTAIL_PARTY"
  },
  verificationResult: {
    _success_title: "SUCCÈS !",
    _success_message: "Merci pour la vérification. Vous pouvez maintenant utiliser le marketing par courriel avec style en utilisant votre adresse électronique !",
    _failure_title: "Désolé !",
    _failure_message: "Il y a eu un problème. Veuillez réessayer ou n'hésitez pas à nous contacter"
  },
  joinPage: {
    _join_title_accepted: "MERCI DE CONFIRMER, CETTE INVITATION EST STRICTEMENT CONFIDENTIELLE ET RÉSERVÉE À :",
    _join_title_declined: "VOUS AVEZ DÉCLINÉ VOTRE PARTICIPATION",
    _join_title_already_accept: "QUE VOUS AVEZ DÉJÀ CONFIRMÉ. MERCI.",
    _join_title_already_decline: "VOUS AVEZ DÉJÀ REFUSÉ. MERCI.",
    _join_reservedfor: "CETTE INVITATION EST STRICTEMENT CONFIDENTIELLE ET RÉSERVÉE AUX :",
    _join_description: "Veuillez lire et confirmer notre politique de confidentialité et nos conditions générales afin de compléter le processus.",
    _join_confirm: "Confirmez",
    _join_confirm_decline: "Refuser",
    _join_alert_policy: "La politique obligatoire de protection de la vie privée n'est toujours pas acceptée.",
    _join_alert_fields: "Les champs obligatoires ne sont toujours pas remplis.",
    _privacy_policy: "Politique de protection de la vie privée",
    _custom_fields: "Plus d'informations",
    _mandatory: "Obligatoire",
    _join_error_invalid_invitation: "Invitation non valable",
    _join_error_event_not_found: "Evénement non trouvé",
    _join_error_event_full: "Nous sommes désolés de vous informer que notre liste est maintenant fermée.",
    _join_error_guest_not_found: "Invité non trouvé",
    _join_error_policies_not_accepted: "Veuillez lire et confirmer notre politique de confidentialité et nos conditions générales afin de compléter le processus.",
    _join_error_unhandled: "Invitation non valable",
    _number_of_guests: "Sélectionnez le nombre de vos invités",
    _info_of_guests: "Veuillez indiquer le nom de vos invités",
    _guest: "Invité",
    _guest_name: "Nom",
    _guest_surname: "Nom de famille"
  },
  registrationPage: {
    _eventFull: "Ops, l'événement est complet maintenant. Veuillez vérifier plus tard si des places sont de nouveau disponibles.",
    _eventFull2: "Oups, l'événement est complet maintenant. Vérifiez plus tard si des places sont à nouveau disponibles ou réduisez le nombre d'invités que vous amenez avec vous si vous en ajoutez.",
    _title: "Inscrivez-vous pour rejoindre l'événement",
    _loggedInAs: "Vous êtes connecté en tant que",
    _logout: "Déconnexion",
    _alreadyAccount: "Vous avez déjà un compte ?",
    _login: "Connexion",
    _signup: "Inscrivez-vous",
    _prefillData: "à préremplir avec vos données",
    _mandatory: "(obligatoire)",
    _firstName: "Prénom",
    _lastName: "Nom de famille",
    _password: "Mot de passe",
    _retypePassword: "Retapez le mot de passe",
    _alreadyJoined: "Vous avez déjà rejoint cet événement.",
    _takeSomeone: "Voulez-vous emmener quelqu'un avec vous ?",
    _personsWithYou: "Personnes à emmener avec vous",
    _personsUpTo: "Vous pouvez prendre jusqu'à",
    _persons: "personnes.",
    _person: "Personne",
    _register: "S'inscrire",
    _thankYouWaiting: "Merci de vous être inscrit à cet événement, nous vous avons envoyé un e-mail pour confirmer votre compte et finaliser votre inscription à l'événement.",
    _thankYouWaiting2: "Merci de vous être inscrit, nous vous avons envoyé un e-mail pour confirmer votre compte.",
    _emailInUse: "E-mail déjà enregistré, veuillez vous connecter pour participer à l'événement.",
    _emailInUse2: "E-mail déjà enregistré.",
    _thankYou: "Merci de vous être inscrit à cet événement.",
    _basicInformation: "INFORMATIONS DE BASE",
    _setPassword: " FIXER LE MOT DE PASSE ",
    _moreInformation: "PLUS D'INFORMATIONS",
    _bookTimeSlots: "RÉSERVER DES CRÉNEMENTS HORAIRES"
  },
  unsubscribePage: {
    _title: "Mailing List",
    _action_confirm: "Cliquez ci-dessous si vous ne souhaitez plus recevoir d'e-mails de cet organisateur. Vous pourrez vous réinscrire à tout moment à partir de cette page.",
    _unsubscribe_confirm: "Cliquez pour vous désinscrire",
    _merci_you_for_unsubscription: "Vous avez été retiré de la liste de diffusion de cet organisateur, si vous souhaitez vous réinscrire, cliquez sur le bouton ci-dessous. Pour plus d'informations, contactez",
    _resubscribe_reverse: "Cliquez pour vous réabonner",
    _merci_you_for_resubscribe: "Merci de vous être abonné!",
    _unsubscribe_reverse: "Annuler les modifications",
    _resubscribe_confirm: "Cliquez pour vous abonner",
  },
  ElementTable: {
    name: "Nom",
    surname: "Nom de famille",
    email: "Courrier électronique"
  },
  teamPage: {
    _new_member: "Ajouter l'adresse électronique d'un nouveau membre",
    _role: "Rôle",
    _invite: "Inviter",
    _invite_title: "Ajouter un membre",
    _invite_subtitle: "Vous pouvez ici ajouter de nouveaux membres à votre équipe. Ils recevront un e-mail d'invitation",
    _team_table_title: "Membres de l'équipe",
    _team_table_subtitle: "Ici, vous pouvez inspecter/modifier les membres de votre équipe",
    _page_title: "Mon équipe",
    _invitation_sent: "Invitation envoyée !",
    _error_email_invalid: "Une adresse électronique valide est requise",
    _error_role_invalid: "Veuillez choisir un rôle",
    _updated: "Mise à jour",
    _warning_remove: "Voulez-vous vraiment supprimer",
    _post_remove: "L'utilisateur sera bientôt supprimé"
  },
  modalBulkAction: {
    _user_selected: "utilisateur(s) sélectionné(s)",
    _cancel: "Annuler",
    _confirm: "Confirmer",
    _search_event: "Recherchez",
    _users_added: "Utilisateurs ajoutés avec succès",
    _users_removed: "Utilisateurs supprimés avec succès",
    _failed: "L'opération est un échec",
    addToEvent: "Affecter à un événement",
    removeFromEvent: "Se retirer d'un événement",
    clearSelection: "Une sélection claire",
    _bulk_action: "Action de masse",
    _options: "Options",
    _warning_select_users: "Sélectionnez d'abord quelques utilisateurs",
    _warning_select_event: "Sélectionnez d'abord un événement"
  },
  errorsNotFound: {
    _page_not_found: "Page non trouvée",
    _page_not_found_desc: "Cette page peut être privée ou vous n'avez pas besoin d'autorisation pour la voir.",
    _go_to_home: "Aller à la page d'accueil"
  },
  badgePrinting: {
    _loadingMessage: "Attendre",
    _title: "Badge",
    _innertitle: "Permettre l'impression des badges",
    _save: "Sauvegarder",
    _saved: "Insigne sauvé",
    _reset: "Reset",
    _formTitle: "Titre",
    _formDescription: "Description",
    _formPageW: "Largeur de la page",
    _formPageH: "Hauteur de la page",
    _formFileUpload: "Cliquez pour télécharger",
    _formFileUploadTips: " fichiers jpg/png d'une taille inférieure à 500 ko ",
    _formTextColor: "Couleur du texte",
    _formBorderColor: "Couleur de la bordure",
    _formField1: "Champ 1",
    _formField2: "Champ 2",
    _formCustomLayout: "Code de mise en page"
  },
  recordings: {
    _page_title: "Enregistrements",
    _subtitle: "Dans cette section, vous pouvez télécharger tous les enregistrements.",
    _warning_remove: "Voulez-vous vraiment supprimer",
    _post_remove: "Enregistrement supprimé"
  },
  customCategories: {
    _label: "Nom de la catégorie",
    _submit: "Créer",
    _created: "Créé",
    _failed: "Échec",
    _warnBeforeDelete: "Certains de vos événements utilisent cette catégorie. Par conséquent, cette opération marquera la catégorie comme supprimée et la désactivera pour continuer à les prendre en charge. Voulez-vous continuer ?",
  },
  eventGroup: {
    _newGroup: "Nom unique du groupe",
    _groupName: "Nom du groupe",
    _removeFromGroup: "Supprimer",
    _markAsController: "Marquer comme contrôleur",
    _markedAsController: "Marqué comme contrôleur",
    _submit: "Créer",
    _created: "Créé",
    _save: "Enregistrer",
    _editGroup: "Modifier le groupe d'événements",
    _cancel: "Annuler",
    _failed: "Échec",
    _addEvent: "Ajouter un événement",
    _searchEvents: "Rechercher des événements",
    _eventGroupExist: "Ce nom de groupe d'événements existe déjà, essayez quelque chose de nouveau",
    _selectController: "Sélectionnez au moins un événement de contrôleur",
    _selectNonController: "Sélectionnez au moins un événement non-contrôleur",
    _eventListEmpty: "Veuillez d'abord ajouter un événement",
    _groupNameMissing: "Le nom du groupe est manquant",
    _warnBeforeDelete: "Êtes-vous sûr de vouloir supprimer ce groupe d'événements ? La suppression de ce groupe d'événements n'affectera pas les événements individuels de ce groupe.",
    _warnBeforeDeletingLastEvent: "Vous ne pouvez pas supprimer tous les événements d'un groupe. Envisagez plutôt de supprimer le groupe.",
  },
  notify: {
    _page_title:"Push Notifications",
    _subtitle:"Créez des notifications push pour des événements spécifiques ou pour partager des mises à jour importantes avec les invités",
    _no_notifications: "Aucune notification n'a encore été envoyée",
    _create:"Créer un nouveau",
    _create_section_title : " Créer une nouvelle notification push",
    _title:"Titre",
    _title_placeholder:"Entrez le titre de la notification push",
    _body:"Body",
    _body_placeholder: "Texte de notification",
    _recap_section_title: "Récapitulatif des notifications",
    _user_group_section_title: "Sélectionnez le groupe d'utilisateurs",
    _title_recommendation: "Nous vous recommandons d'utiliser un maximum de 10 mots",
    _submit: "Soumettre",
    _all_users: "Tous les utilisateurs ou invités - (Tous les événements)",
    _subtitle_user_group:"Veuillez noter que les notifications sont limitées aux utilisateurs qui ont téléchargé l'application invitée et participent à l'événement",
    _warnBeforeSend: "Êtes-vous sûr d'envoyer la notification ?",
    _sending: "Envoi",
  },
  showOnApp: {
    _title: "Afficher sur l'application",
    _description: "Afficher cet événement dans votre application invité"
  },
  publicPages: {
    _book_test: "Réserver un test",
    _download_ticket: "Télécharger le ticket",
    _certify_test: "Certifier le résultat du test",
    _join: "Rejoindre",
    _reset_password: "Réinitialiser le mot de passe",
    _reset_message: "Vous recevrez un e-mail de mailing@penguinpass.it pour définir un nouveau mot de passe.",
    _password: "Mot de passe",
    _forgot_password: "Mot de passe oublié?",
    _back_login: "Retour à la connexion",
    _reset: "Réinitialiser",
    _register: "S'inscrire",
    _name: "Nom",
    _surname: "Nom de famille",
    _email: "E-mail",
    _test_not_verified: "Test non vérifié.",
    _test_not_verified_sub: "Il faut faire le test pour voir le ticket. Veuillez vous assurer que vous avez passé le test et qu'il est dans le délai demandé."
  },
  c19compliance: {
    _title: "RÉGLEMENTATION COVID-19",
    _description: "Activer le contrôle",
    timePeriod: "Définir la période de temps",
    subTimePeriod: "Définir le délai avant l'événement pendant lequel le test sera considéré comme valide"
  },
  oauth: {
    _redirecting_you: "Veuillez patienter, nous vous redirigeons"
  },
  RSVPImageHeader: {
    _title: "Personnaliser la Thank you page",
    _innertitle: "Activer I'image",
    _subtitle: "Ici, vous pouvez ajouter un logo pour personnaliser la thank you page  que vos invités verront lorsqu'ils confirmeront ou refuseront vos invitations"
  }
};