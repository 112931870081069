<template>
  <el-container direction="vertical" class="guest-list-container">
    <div class="group-manager-members" style="margin-top: 55px">
      <!-- USER LIST -->
      <h4 class="form-section-title" v-if="!isExhibitor">
        {{ string.guestlist._title }}
        <small>{{ string.guestlist._subtitle }}</small>
      </h4>
      <div class="smallseparator" v-if="!isExhibitor"></div>
      <div class="row" v-if="!isExhibitor">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <a
            class="btn gray-btn import-man"
            :title="string.guestlist._add"
            @click="toggleInsertSingle"
          >
            <i class="fa fa-plus" aria-hidden="true" />
            {{ string.guestlist._add }}
          </a>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-6">
          <a
            class="btn gray-btn upload"
            :title="string.guestlist._upload"
            @click="toggleUploadList"
          >
            <i class="fa fa-upload" aria-hidden="true" />
            {{ string.guestlist._upload }}
          </a>
        </div>
        <div v-if="hubiloActive" class="col-sm-12 col-md-6 col-lg-6">
          <a
            class="btn gray-btn upload"
            :title="string.guestlist._hubiloEvent"
            @click="toggleInsertHubilo"
          >
            {{ string.guestlist._hubiloEvent }}
          </a>
        </div>
        <div v-if="hubiloActive && newHubiloEventId" class="col-sm-12 col-md-6 col-lg-6">
          <a
            class="btn gray-btn upload"
            :title="string.guestlist._reload"
            @click="reloadHubiloEvent"
          >
            {{ string.guestlist._reload }}
          </a>
        </div>
      </div>

      <!-- Tab Content -->
      <div class="row tabs-content">
        <transition name="component-fade-fast" mode="out-in">
          <div v-if="tabValue === 'uploadlist'" key="uploadlist">
            <ListUpload
              :event-id="eventId"
              :father-name="componentName"
              @component_event="closeTab"
            />
          </div>
          <div v-if="tabValue === 'insertsingle'" key="insertsingle">
            <ListSingleItem
              :team="team"
              :event-id="eventId"
              :father-name="componentName"
              @component_event="closeTab"
            />
          </div>
          <div v-if="tabValue === 'inserthubilo'" key="inserthubilo">
            <input v-model="newHubiloEventId" type="text" class="form-control" />
            <button class="btn btn-default col-sm-12 col-md-6 col-lg-6" @click="saveHubiloId()">
              Save
            </button>
          </div>
        </transition>
      </div>

      <h4 v-if="uploadStatus !== 'NULL'" class="form-section-title second progress-title">
        {{ uploadStatusMessage }} <loading-icon v-if="uploadStatus !== 'PENDING'" />
        <small />
      </h4>
      <el-progress
        v-if="uploadStatus !== 'NULL'"
        class="progress-container"
        :stroke-width="24"
        :percentage="uploadProgress"
        :show-text="false"
        :color="'#f7a30c'"
        :format="formatProgress"
      />
      <h4 class="form-section-title second">
        {{ string.guestlist._list }}
      </h4>
      <div class="smallseparator"></div>
      <div class="downloads_style">
        <i class="fa fa-users" aria-hidden="true" style="margin-right: 15px" />
        {{ string.guestlist._guestnumber }}<strong>{{ totalGuests }}</strong>
      </div>

      <div class="downloads_style">
        <i class="fa fa-download" aria-hidden="true" style="margin-right: 15px" />
        <button
          :disabled="exportingReport"
          class="linkStyle"
          @click="fetchDataAndExportReport(false)"
        >
          {{ string.guestlist._downloadlistAll }}
        </button>
      </div>

      <div class="downloads_style">
        <i class="fa fa-download" aria-hidden="true" style="margin-right: 15px" />
        <button
          :disabled="exportingReport"
          class="linkStyle"
          @click="fetchDataAndExportReport(true)"
        >
          {{ string.guestlist._downloadlist }}
        </button>
      </div>

      <div class="downloads_style">
        <i class="fa fa-download" aria-hidden="true" style="margin-right: 15px" />
        <button :disabled="exportingReport" class="linkStyle" @click="fetchDataAndExportPlusOnes()">
          {{ string.guestlist._downloadPlusOneList }}
        </button>
      </div>

      <div class="downloads_style">
        <i class="fa fa-download" aria-hidden="true" style="margin-right: 15px" />
        <button :disabled="exportingReport" class="linkStyle" @click="fetchActivitiesAndExport">
          {{ string.guestlist._downloadActivities }}
        </button>
      </div>
      <div class="downloads_style">
        <i class="fa fa-download" aria-hidden="true" style="margin-right: 15px" />
        <button :disabled="exportingReport" class="linkStyle" @click="fetchAttachmentsAndExport">
          {{ string.guestlist._downloadAttachments }}
        </button>
      </div>
      <div class="downloads_style" v-if="!isExhibitor">
        <!-- <div class="guest-delete-all"> -->
        <i class="fa fa-times" aria-hidden="true" style="margin-right: 20px" />
        <a class="simple" style="padding: 0" @click="deleteListAllFunction">
          {{ string.guestlist._removelist }}
        </a>
        <!-- </div> -->
      </div>
      <div class="downloads_style" v-if="badgePrintingEnabled === true">
        <custom-checkbox v-model="autoPrintBadge" style="margin-right: 18px" />
        {{ string.guestlist._autoPrint }}
      </div>
      <div class="downloads_style" v-if="badgePrintingEnabled === true">
        <custom-checkbox v-model="localPrintBadge" style="margin-right: 18px" />
        {{ string.guestlist._localPrint }}
      </div>

      <div class="audience-filter-area">
        <h4 class="form-section-title first">
          {{ string.guestlist._audience_title }}
          <small>{{ string.guestlist._audience_subtitle }}</small>
        </h4>
        <audience-option-group
          :hide-counter="true"
          :stop-calculate-eligible-recipient-count="true"
          :audience-options="audienceOptions"
          :event-id="eventId"
          :custom-field-filter="customFieldFilter"
          :on-audience-option-changed="onAudienceOptionChanged"
          :on-audience-size-changed="onAudienceSizeChanged"
          :initial-value="initialOption"
        ></audience-option-group>
        <custom-field-filter
          v-if="audienceOption === 'CUSTOM_FIELD'"
          key="CUSTOM_FIELD"
          :custom-field-map="customFieldMapFiltered"
          :on-filter-changed="onFilterChanged"
        >
        </custom-field-filter>
        <custom-field-filter
          v-else-if="audienceOption === 'TIME_SLOT'"
          key="TIME_SLOT"
          :custom-field-map="timeSlotMap"
          :on-filter-changed="onFilterChanged"
        >
        </custom-field-filter>
      </div>
      <el-row style="bottom-margin: 3px">
        <el-col :span="24" style="text-align: right; padding-bottom: 20px">
          <label class="el-form-item_label" style="line-height: 40px">
            {{ string.guestlist._attendeePartnersMax }}:
          </label>
          <NumericFieldInput
            :initial-value="maxGuestsPerMember"
            @change="setEventMaxAcquaintance"
          />
        </el-col>
      </el-row>
      <!-- <div style="text-align: right; float: right;" v-if="!isExhibitor">
      </div> -->
      <div class="group-manager-members-list">
        <data-table
          ref="table"
          :lazy="true"
          :get-data="getData"
          :columns="columns"
          selection-enabled
          :bulk-options="bulkOptions"
          :on-bulk-action="onBulkAction"
          :page-size="15"
          @loaded="ready = true"
        >
          <span slot="memberName" slot-scope="{ row }">
            <transition v-if="row.edit === true" name="component-fade-fast" mode="out-in" appear>
              <input
                v-model="editItem.memberName"
                type="text"
                :placeholder="columns[0].label"
                class="form-control"
                @input="markRowModified(row)"
              />
            </transition>
            <transition v-else name="component-fade-fast" mode="out-in" appear>
              <span>
                {{ row.member.name }}
              </span>
            </transition>
          </span>
          <span slot="memberSurname" slot-scope="{ row }">
            <transition v-if="row.edit === true" name="component-fade-fast" mode="out-in" appear>
              <input
                v-model="editItem.memberSurname"
                type="text"
                :placeholder="columns[1].label"
                class="form-control"
                @input="markRowModified(row)"
              />
            </transition>
            <transition v-else name="component-fade-fast" mode="out-in" appear>
              <span>
                {{ row.member.surname }}
              </span>
            </transition>
          </span>
          <span slot="memberEmail" slot-scope="{ row }">
            <transition v-if="row.edit === true" name="component-fade-fast" mode="out-in" appear>
              <input
                v-model="editItem.memberEmail"
                type="text"
                :placeholder="columns[2].label"
                class="form-control"
                @input="markRowModified(row)"
              />
            </transition>
            <transition v-else name="component-fade-fast" mode="out-in" appear>
              <span>
                {{ row.member.email.includes("internal.penguinpass.it") ? "" : row.member.email }}
              </span>
            </transition>
          </span>

          <span slot="listEmails" slot-scope="{ row }">
            <transition v-if="row.edit === true" name="component-fade-fast" mode="out-in" appear>
              <div class="form-control email-list">
                <el-popover
                  :id="'newEmail_' + row.id"
                  popper-class="email-edit-popper"
                  width="567"
                  trigger="click"
                  placement="bottom"
                >
                  <el-row :gutter="20">
                    <el-col :span="20">
                      <input
                        v-model.trim="newEmail"
                        aria-label=""
                        type="email"
                        class="form-control"
                        placeholder="Add email"
                        @keyup.enter="addNewEmail(row)"
                      />
                    </el-col>
                    <el-col :span="4" style="text-align: center">
                      <a class="action_link" style="font-size: 32px" @click="addNewEmail(row)">
                        <i class="fa fa-plus-circle"></i>
                      </a>
                    </el-col>
                  </el-row>
                  <div
                    v-for="email in editItem.listEmails"
                    :key="email.title"
                    style="padding: 5px 0"
                  >
                    <el-row :gutter="20">
                      <el-col :span="20">
                        <input
                          v-if="email.edit"
                          v-model.trim="email.title"
                          v-focus
                          type="email"
                          aria-label=""
                          class="form-control"
                          style="
                            padding: 10px;
                            text-align: left;
                            background-color: transparent;
                            color: white;
                          "
                          @keyup.enter="
                            () => {
                              email.edit = false;
                              $emit('update');
                            }
                          "
                          @input="markRowModified(row, { updateEmailList: true })"
                        />
                        <p v-else style="color: white">
                          {{ email.title }}
                        </p>
                      </el-col>
                      <el-col :span="2" style="text-align: center">
                        <a
                          class="action_link"
                          style="font-size: 16px"
                          @click="
                            (_) => {
                              email.edit = !email.edit;
                            }
                          "
                        >
                          <i
                            :class="email.edit === false ? 'fa fa-pencil-square-o' : 'fa fa-check'"
                          ></i>
                        </a>
                      </el-col>
                      <el-col :span="2" style="text-align: center">
                        <a
                          class="action_link"
                          style="font-size: 16px"
                          @click="
                            (_) => {
                              deleteEmail(email);
                              markRowModified(row, { updateEmailList: true });
                            }
                          "
                        >
                          <i class="fa fa-trash-o"></i>
                        </a>
                      </el-col>
                    </el-row>
                  </div>
                  <div slot="reference" class="name-wrapper-2-borderless">
                    <el-tag>{{ string.datagrid._edit_emails }}</el-tag>
                  </div>
                </el-popover>
              </div>
            </transition>
            <transition v-else name="component-fade-fast" mode="out-in" appear>
              <div class="email-list">
                <el-popover
                  :id="'listEmail_' + row.id"
                  width="250"
                  trigger="click"
                  placement="bottom"
                  popper-class="email-list-popper"
                >
                  <div
                    v-for="email in row['member']['listEmails']"
                    :key="email"
                    class="row list-email-row"
                  >
                    {{ email }}
                  </div>
                  <div slot="reference" class="name-wrapper-2">
                    <el-tag>{{ string.datagrid ? string.datagrid._list_emails : "" }}</el-tag>
                  </div>
                </el-popover>
              </div>
            </transition>
          </span>

          <span slot="memberTelephone" slot-scope="{ row }">
            <transition v-if="row.edit === true" name="component-fade-fast" mode="out-in" appear>
              <input
                v-model="editItem.memberTelephone"
                type="text"
                :placeholder="columns[4].label"
                class="form-control"
                @input="markRowModified(row)"
              />
            </transition>
            <transition v-else name="component-fade-fast" mode="out-in" appear>
              <span>
                {{ row.member.telephone }}
              </span>
            </transition>
          </span>

          <span slot="acquaintance" slot-scope="{ row }" style="text-align: center">
            <transition v-if="row.edit === true" name="component-fade-fast" mode="out-in" appear>
              <NumericFieldInput
                class="p-table-cell"
                :initial-value="row.maxAcquaintance"
                @change="
                  (val) => {
                    setMaxAcquaintance(val);
                    markRowModified(row);
                  }
                "
              />
            </transition>
            <transition v-else name="component-fade-fast" mode="out-in" appear>
              <span>
                {{ row.acquaintance + "/" + row.maxAcquaintance }}
              </span>
            </transition>
          </span>

          <span slot="deliveryStatus" slot-scope="{ row }">
            <transition v-if="row.edit === true" name="component-fade-fast" mode="out-in" appear>
              <input
                aria-label=""
                type="text"
                readonly="true"
                class="form-control"
                :value="row.deliveryStatus.toLowerCase()"
              />
            </transition>
            <transition v-else name="component-fade-fast" mode="out-in" appear>
              <div
                v-if="
                  [
                    'SEND',
                    'DELIVERED',
                    'OPENED',
                    'CLICKED',
                    'BOUNCED',
                    'REJECTED',
                    'COMPLAINED',
                  ].indexOf(row.deliveryStatus) !== -1
                "
                class="p-table-cell"
                style="text-align: left !important; line-height: 48px"
              >
                <el-tooltip
                  effect="dark"
                  :content="
                    row.deliveryStatus
                      ? row.deliveryStatus === 'COMPLAINED'
                        ? 'unsubscribed'
                        : row.deliveryStatus.toLowerCase()
                      : '-'
                  "
                  placement="bottom"
                >
                  <font-awesome-layers class="fa-lg">
                    <font-awesome-icon :icon="['fas', 'circle']" transform="grow-6">
                    </font-awesome-icon>
                    <font-awesome-icon
                      :icon="deliveryStatusIcons[row.deliveryStatus]"
                      transform="shrink-4"
                      :style="{ color: 'black' }"
                    >
                    </font-awesome-icon>
                  </font-awesome-layers>
                </el-tooltip>
              </div>
              <div
                v-else
                class="p-table-cell"
                style="text-align: left !important; line-height: 48px"
              >
                -
              </div>
            </transition>
          </span>

          <span slot="status" slot-scope="{ row }">
            <transition v-if="row.edit === true" name="component-fade-fast" mode="out-in" appear>
              <el-select
                v-model="editItem.status"
                class="p-table-cell"
                popper-class="default-popper"
                size="large"
                placeholder="Status"
                @input="markRowModified(row)"
              >
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                  :hidden="item.disabled"
                >
                </el-option>
              </el-select>
            </transition>
            <transition v-else name="component-fade-fast" mode="out-in" appear>
              <div class="p-table-cell" style="text-align: left !important; line-height: 48px">
                <font-awesome-layers class="fa-lg" style="margin-right: 10px">
                  <font-awesome-icon
                    :icon="['fas', 'circle']"
                    transform="grow-6"
                    :style="statusStyle[row.status]"
                  >
                  </font-awesome-icon>
                  <font-awesome-icon
                    :icon="statusIcons[row.status]"
                    transform="shrink-4"
                    :style="{ color: 'white' }"
                  >
                  </font-awesome-icon>
                </font-awesome-layers>
                <span>{{ statusDescription[row.status] }}</span>
              </div>
            </transition>
          </span>
          <span slot="virtualCheckInAt" slot-scope="{ row }">
            <transition v-if="row.edit === true" name="component-fade-fast" mode="out-in" appear>
              <input
                aria-label=""
                type="text"
                readonly="true"
                class="form-control"
                :value="row.virtualCheckInAt ? new Date(row.virtualCheckInAt).format() : '-'"
              />
            </transition>
            <transition v-else name="component-fade-fast" mode="out-in" appear>
              <div
                v-if="!!row.virtualCheckInAt"
                class="p-table-cell"
                style="text-align: left !important; line-height: 48px"
              >
                <el-tooltip
                  effect="dark"
                  :content="row.virtualCheckInAt ? new Date(row.virtualCheckInAt).format() : '-'"
                  placement="bottom"
                >
                  <font-awesome-layers class="fa-lg">
                    <font-awesome-icon :icon="['fas', 'circle']" transform="grow-6">
                    </font-awesome-icon>
                    <font-awesome-icon
                      icon="check"
                      transform="shrink-4"
                      :style="{ color: 'black' }"
                    >
                    </font-awesome-icon>
                  </font-awesome-layers>
                </el-tooltip>
              </div>
              <div
                v-else
                class="p-table-cell"
                style="text-align: left !important; line-height: 48px"
              >
                -
              </div>
            </transition>
          </span>
          <span slot="isRegistered" slot-scope="{ row }">
            <transition v-if="row.edit === true" name="component-fade-fast" mode="out-in" appear>
              <input
                aria-label=""
                type="text"
                readonly="true"
                class="form-control"
                :value="row.member.isRegistered === true ? 'Yes' : 'No'"
              />
            </transition>
            <transition v-else name="component-fade-fast" mode="out-in" appear>
              <span>
                {{ row.member.isRegistered ? "Yes" : "No" }}
              </span>
            </transition>
          </span>
          <template
            v-for="[customFslug, cField] in memberCustomFieldMapArray"
            :slot="customFslug"
            slot-scope="{ row }"
          >
            <span :key="customFslug">
              <transition v-if="row.edit === true" name="component-fade-fast" mode="out-in" appear>
                <span v-if="cField.disabled">
                  {{ customFieldAnswerToString(row.member.memberCustomFieldDataMap[customFslug]) }}
                </span>
                <input
                  v-else-if="cField.type === 'OPEN_ANSWER'"
                  v-model="editItem.memberCustomFieldDataMap[customFslug]"
                  aria-label=""
                  type="text"
                  class="form-control"
                  :placeholder="cField.options.question"
                  @input="markRowModified(row, { updateCustomFields: true })"
                />
                <el-select
                  v-else-if="cField.type === 'MULTIPLE_CHOICE'"
                  v-model="editItem.memberAnswerList[customFslug]"
                  class="p-table-cell"
                  popper-class="default-popper"
                  aria-autocomplete="password"
                  size="large"
                  multiple
                  collapse-tags
                  filterable
                  :multiple-limit="cField.options.maxSelection"
                  :placeholder="cField.options.question"
                  @change="(value) => onUpdateMultipleChoiceMember(row, slug, value)"
                >
                  <el-option
                    v-for="[answerSlug, answer] in Object.entries(cField.options.answers)"
                    :key="answerSlug"
                    :hidden="
                      answer.disabled || (answer.maxLimit > 0 && answer.limit >= answer.maxLimit)
                    "
                    :label="answer.label"
                    :value="answerSlug"
                  >
                    {{ answer.label }}
                  </el-option>
                </el-select>
                <el-select
                  v-else-if="cField.type === 'TIME_SLOT'"
                  v-model="editItem.memberAnswerList[customFslug]"
                  class="p-table-cell"
                  popper-class="default-popper"
                  clearable
                  aria-autocomplete="password"
                  size="large"
                  :placeholder="cField.options.question"
                  @change="(value) => onUpdateTimeSlotMember(row, slug, value)"
                >
                  <el-option
                    v-for="[answerSlug, answer] in Object.entries(cField.options.answers)"
                    :key="answerSlug"
                    :hidden="
                      answer.disabled || (answer.maxLimit > 0 && answer.limit >= answer.maxLimit)
                    "
                    :label="answer.label"
                    :value="answerSlug"
                  >
                    {{ answer.label }}
                  </el-option>
                </el-select>
                <span v-else>
                  {{ customFieldAnswerToString(row.member.memberCustomFieldDataMap[customFslug]) }}
                </span>
              </transition>
              <transition v-else name="component-fade-fast" mode="out-in" appear>
                <span
                  v-if="
                    customFieldAnswerToString(
                      row.member.memberCustomFieldDataMap[customFslug]
                    ).indexOf('://') === -1
                  "
                >
                  {{ customFieldAnswerToString(row.member.memberCustomFieldDataMap[customFslug]) }}
                </span>
                <span
                  v-else
                  v-html="
                    `<a href='${customFieldAnswerToString(
                      row.member.memberCustomFieldDataMap[customFslug]
                    )}'><i class='fa fa-download' aria-hidden='true'></i></a>`
                  "
                ></span>
              </transition>
            </span>
          </template>
          <template v-for="[slug, cField] in customFieldArray" :slot="slug" slot-scope="{ row }">
            <span :key="slug">
              <transition v-if="row.edit === true" name="component-fade-fast" mode="out-in" appear>
                <span v-if="cField.disabled">
                  {{ customFieldAnswerToString(row.customFieldDataMap[slug]) }}
                </span>
                <input
                  v-else-if="cField.type === 'OPEN_ANSWER'"
                  v-model="editItem.customFieldDataMap[slug]"
                  aria-label=""
                  type="text"
                  class="form-control"
                  :placeholder="cField.options.question"
                  @input="markRowModified(row, { updateCustomFields: true })"
                />
                <el-select
                  v-else-if="cField.type === 'MULTIPLE_CHOICE'"
                  v-model="editItem.answerList[slug]"
                  class="p-table-cell"
                  popper-class="default-popper"
                  aria-autocomplete="password"
                  size="large"
                  multiple
                  collapse-tags
                  filterable
                  :multiple-limit="cField.options.maxSelection"
                  :placeholder="cField.options.question"
                  @change="(value) => onUpdateMultipleChoice(row, slug, value)"
                >
                  <el-option
                    v-for="[answerSlug, answer] in Object.entries(cField.options.answers)"
                    :key="answerSlug"
                    :hidden="
                      answer.disabled || (answer.maxLimit > 0 && answer.limit >= answer.maxLimit)
                    "
                    :label="answer.label"
                    :value="answerSlug"
                  >
                    {{ answer.label }}
                  </el-option>
                </el-select>
                <el-select
                  v-else-if="cField.type === 'TIME_SLOT'"
                  v-model="editItem.answerList[slug]"
                  class="p-table-cell"
                  popper-class="default-popper"
                  clearable
                  aria-autocomplete="password"
                  size="large"
                  :placeholder="cField.options.question"
                  @change="(value) => onUpdateTimeSlot(row, slug, value)"
                >
                  <el-option
                    v-for="[answerSlug, answer] in Object.entries(cField.options.answers)"
                    :key="answerSlug"
                    :hidden="
                      answer.disabled || (answer.maxLimit > 0 && answer.limit >= answer.maxLimit)
                    "
                    :label="answer.label"
                    :value="answerSlug"
                  >
                    {{ answer.label }}
                  </el-option>
                </el-select>
                <span v-else>
                  {{ customFieldAnswerToString(row.customFieldDataMap[slug]) }}
                </span>
              </transition>
              <transition v-else name="component-fade-fast" mode="out-in" appear>
                <span
                  v-if="
                    customFieldAnswerToString(row.customFieldDataMap[slug]).indexOf('://') === -1
                  "
                >
                  {{ customFieldAnswerToString(row.customFieldDataMap[slug]) }}
                </span>
                <span
                  v-else
                  v-html="
                    `<a href='${customFieldAnswerToString(
                      row.customFieldDataMap[slug]
                    )}'><i class='fa fa-download' aria-hidden='true'></i></a>`
                  "
                ></span>
              </transition>
            </span>
          </template>
          <span slot="Actions" slot-scope="{ row }" style="text-align: center">
            <!-- <el-button-group> -->
            <a
              class="action_link"
              style="margin-right: 5px"
              @click="() => (row.edit === false ? openRow(row) : saveRow(row))"
            >
              <i :class="row.edit === false ? 'fa fa-pencil-square-o' : 'fa fa-check'"></i>
            </a>
            <a
              class="action_link"
              style="margin-right: 5px"
              @click="() => (row.edit === false ? deleteRow(row) : closeRow(row))"
            >
              <i :class="row.edit === false ? 'fa fa-trash' : 'fa fa-times'"></i>
            </a>
            <a
              v-if="row.edit === false && badgePrintingEnabled === true"
              class="action_link"
              @click="downloadBadge(row)"
            >
              <i class="fa fa-id-badge"></i>
            </a>
            <a
              v-if="row.edit === false && badgePrintingEnabled === true"
              class="action_link"
              @click="printBadge(row)"
            >
              <i class="fa fa-print"></i>
            </a>
            <a
              v-if="row.edit === false"
              class="action_link"
              style="margin-right: 5px"
              @click="() => (checkRow(row))"
            >
              <i class="fa fa-check-square-o"></i>
            </a>
            <!-- </el-button-group> -->
          </span>
        </data-table>
      </div>
    </div>
  </el-container>
</template>
<script>
import DataTable from "../../components/DataTable";
import LoadingCover from "../../components/LoadingCover.vue";
import ListSingleItem from "../../components/ListSingleItem.vue";
import ListUpload from "../../components/ListUpload.vue";
import NumericFieldInput from "../../components/NumericFieldInput.vue";
import LoadingIcon from "../../components/LoadingIcon.vue";
import {
  GUEST_LIST,
  DELETE_ALL,
  UPDATE_EVENT,
  UPDATE_GUEST_LIST_MAX_ACQUAINTANCE,
  DELETE_ONE_GUEST,
  UPDATE_MEMBER_BY_ID,
  UPDATE_GUEST_BY_ID,
  SUBSCRIBE_TO_GUEST_NEWS,
  SUBSCRIBE_TO_GUEST_LIST_IMPORT,
  GUEST_LIST_FOR_REPORT,
  IMPORT_HUBILO_EVENT,
  ELIGIBLE_RECIPIENT_COUNT,
  GET_ACTIVITY_REPORT,
} from "@/graphql";
import { subject } from "@casl/ability";
import { mapGetters } from "vuex";
import isEmail from "validator/lib/isEmail";
import { customFieldAnswerToString } from "@/utils";
import CustomFieldFilter from "../CustomFieldFilter.vue";
import AudienceOptionGroup from "../AudienceOptionGroup.vue";
import CustomCheckbox from "@/components/ui/CustomCheckbox.vue";
import * as JSZip from "jszip";
import { TEXT_ONLY } from "@/utils/constants";

export default {
  name: "GuestListContainer",
  components: {
    DataTable,
    LoadingIcon,
    ListSingleItem,
    ListUpload,
    NumericFieldInput,
    AudienceOptionGroup,
    CustomFieldFilter,
    CustomCheckbox,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    eventId: {
      type: String,
      required: true,
    },
    isPrivate: {
      type: Boolean,
      required: false,
      default: false,
    },
    confirmationPlusOne: {
      type: Number,
      required: false,
      default: 0,
    },
    maxGuestsPerEvent: {
      type: Number,
      required: false,
      default: 0,
    },
    maxGuestsPerMember: {
      type: Number,
      required: false,
      default: 0,
    },
    customFieldMap: {
      type: Object,
      required: false,
      default: () => {},
    },
    customFieldMapFiltered: {
      type: Object,
      required: false,
      default: () => {},
    },
    eventName: {
      type: String,
      required: false,
      default: "",
    },
    badgePrintingEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    timeSlotMap: {
      type: Object,
      required: false,
      default: () => {},
    },
    timeSlotEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    hubiloActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    hubiloEventId: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      componentName: "guest-list-container",
      string: {
        groupManager: {},
      },
      autoPrintBadge: false,
      localPrintBadge: false,
      updateEmailList: false,
      updateCustomFields: false,
      newEmail: "",
      editItem: {
        memberName: "",
        memberSurname: "",
        memberEmail: "",
        memberTelephone: "",
        listEmails: [],
        status: "",
        customFieldDataMap: {},
        answerList: {},
        memberCustomFieldDataMap: {},
        memberAnswerList: {},
      },
      editItemCopy: {
        memberName: "",
        memberSurname: "",
        memberEmail: "",
        memberTelephone: "",
        listEmails: [],
        status: "",
        customFieldDataMap: {},
        answerList: {},
        memberCustomFieldDataMap: {},
        memberAnswerList: {},
      },
      tabValue: "",
      columns: [
        {
          prop: "memberName",
          label: "Name",
          minWidth: "150",
          fixed: "left",
        },
        {
          prop: "memberSurname",
          label: "Surname",
          minWidth: "150",
          fixed: "left",
        },
        {
          prop: "status",
          label: "Status",
          minWidth: "200",
        },
        {
          prop: "memberEmail",
          label: "Primary Email",
          minWidth: "250",
        },
        {
          prop: "listEmails",
          label: "Emails",
          minWidth: "130",
        },
        {
          prop: "memberTelephone",
          label: "Tel",
          minWidth: "150",
        },
        {
          prop: "acquaintance",
          label: "+1",
          minWidth: "170",
        },
        {
          prop: "deliveryStatus",
          label: "Campaign",
          minWidth: "110",
        },
        {
          prop: "isRegistered",
          label: "App",
          minWidth: "100",
        },
        {
          prop: "virtualCheckInAt",
          label: "Virtual Check-in",
          minWidth: "150",
        },
        {
          label: "Actions",
          width: "130",
          fixed: "right",
        },
      ],
      deliveryStatusIcons: {
        SEND: ["far", "paper-plane"],
        DELIVERED: ["far", "envelope"],
        OPENED: ["far", "envelope-open"],
        CLICKED: ["fas", "envelope-open-text"],
        BOUNCED: ["fas", "times"],
        REJECTED: ["fas", "times"],
        COMPLAINED: ["fas", "times"],
      },
      statusIcons: {
        NULL: "times",
        PENDING: "exclamation",
        WAITING_VERIFICATION: "question-circle",
        CONFIRMED: "thumbs-up",
        CHECK_IN: "check",
        DECLINE: "times",
        WILL_BE_BACK: "undo",
        CHECKED_OUT: "sign-out-alt",
      },
      statusStyle: {
        NULL: { color: "#DC143C" },
        PENDING: { color: "#555555" },
        WAITING_VERIFICATION: { color: "#E6A23C" },
        CONFIRMED: { color: "#2980b9" },
        CHECK_IN: { color: "#ff9c00" },
        DECLINE: { color: "#DC143C" },
        WILL_BE_BACK: { color: "#9b59b6" },
        CHECKED_OUT: { color: "#1abc9c" },
      },
      statusDescription: {
        NULL: "-",
        PENDING: "pending",
        WAITING_VERIFICATION: "waiting verification",
        CONFIRMED: "confirmed",
        CHECK_IN: "check-in",
        DECLINE: "declined",
        WILL_BE_BACK: "will be back",
        CHECKED_OUT: "check-out",
      },
      statusOptions: [
        { value: "PENDING", label: "pending" },
        { value: "WAITING_VERIFICATION", label: "waiting verification" },
        { value: "CONFIRMED", label: "confirmed" },
        { value: "CHECK_IN", label: "check-in" },
        { value: "DECLINE", label: "declined" },
        { value: "WILL_BE_BACK", label: "will be back" },
        { value: "CHECKED_OUT", label: "check-out" },
      ],
      guestNewsSubscription: null,
      guestListImportTaskSubscription: null,
      bulkOptions: [],

      // upload management
      uploadStatus: "NULL",
      uploadStatusMessage: "",
      uploadProgress: 0,
      totalGuests: 0,
      newHubiloEventId: this.hubiloEventId,
      reportData: [],
      plusOneColumns: [
        {
          label: "Event",
          field: "eventId",
          dataFormat: (value) => {
            return this.eventName;
          },
        },
        {
          label: "Firstname*",
          field: "name",
        },
        {
          label: "Surname*",
          field: "surname",
        },
        {
          label: "Email*",
          field: "email",
        },
        {
          label: "Alternative Emails",
          field: "alternativeEmails",
        },
        {
          label: "Max Acquaintance",
          field: "maxAcquaintance",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Tel",
          field: "tel",
        },
        {
          label: "Invited from",
          field: "invitedFrom",
        },
      ],
      reportColumns: [
        {
          label: "Event",
          field: "eventId",
          dataFormat: (value) => {
            return this.eventName;
          },
        },
        {
          label: "Name",
          field: "member",
          dataFormat: (value) => {
            return value.name;
          },
        },
        {
          label: "Surname",
          field: "member",
          dataFormat: (value) => {
            return value.surname;
          },
        },
        {
          label: "Email",
          field: "member",
          dataFormat: (value) => {
            return value.email.includes("internal.penguinpass.it") ? "" : value.email;
          },
        },
        {
          label: "Alternative Emails",
          field: "member",
          dataFormat: (value) => {
            let output = "";
            const emailList = value["listEmails"];
            for (const [index, email] of emailList.entries()) {
              if (index !== emailList.length - 1) {
                if (email && email.trim() !== "") {
                  output += email + ", ";
                }
              } else {
                if (email && email.trim() !== "") {
                  output += email;
                } else {
                  // Remove previous comma because last one failed
                  output = output.slice(0, output.length - 2);
                }
              }
            }
            return output;
          },
        },
        {
          label: "Tel",
          field: "member",
          dataFormat: (value) => {
            return value.telephone;
          },
        },
        {
          label: "Actual +1",
          field: "acquaintance",
        },
        {
          label: "Forecasted +1",
          field: "maxAcquaintance",
        },
        {
          label: "+1 Info",
          field: "acquaintanceInfoList",
          dataFormat: (values) => {
            let result = "";
            for (const value of values) {
              result += `${value.name ? value.name : ""}, ${value.surname ? value.surname : ""}, ${
                value.email ? value.email : ""
              }; `;
            }
            return result;
          },
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Campaign Status",
          field: "deliveryStatus",
          dataFormat: (value) => {
            return value && value !== "DRAFT" ? value : "-";
          },
        },
        {
          label: "Rsvp Date",
          field: "rsvpDate",
          dataFormat: (value) => {
            return value ? new Date(value).format() : "";
          },
        },
        {
          label: "Checkin Date",
          field: "activity",
          dataFormat: (value) => {
            if (value && value.length) {
              for (const activity of value) {
                if (activity.activity === "CHECK_IN") {
                  return new Date(activity.createdAt).format();
                }
              }
            }
            return "";
          },
        },
        {
          label: "Virtual Check-in",
          field: "virtualCheckInAt",
          dataFormat: (value) => {
            return value ? new Date(value).format() : "";
          },
        },
        {
          label: "Is Registered",
          field: "member",
          dataFormat: (value) => {
            return value["isRegistered"] ? "yes" : "no";
          },
        },
        {
          label: "Added From Check-in App",
          field: "fromCheckinApp",
          dataFormat: (value) => {
            return value ? "yes" : "no";
          },
        },
      ],
      exportingReport: false,
      activityReportData: [],
      activityReportColumns: [
        {
          label: "Name",
          field: "memberName",
        },
        {
          label: "Surname",
          field: "memberSurname",
        },
        {
          label: "Email",
          field: "memberEmail",
          dataFormat: (value) => {
            return value.includes("internal.penguinpass.it") ? "" : value;
          },
        },
        {
          label: "Tel",
          field: "memberTelephone",
        },
        {
          label: "Scanning for +1",
          field: "scanningForAcquaintance",
          dataFormat: (value) => {
            return value === true ? "yes" : "no";
          },
        },
        {
          label: "Added From Check-in App",
          field: "fromCheckinApp",
          dataFormat: (value) => {
            return value ? "yes" : "no";
          },
        },
        {
          label: "Activity",
          field: "activity",
        },
        {
          label: "Date",
          field: "createdAt",
          dataFormat: (value) => {
            return value ? new Date(value).format() : "";
          },
        },
      ],
      //audience management
      audienceSize: 0,
      customFieldFilter: { question: "", answer: "", type: "", source: "" },
      initialFilter: { question: "", answer: "", type: "", source: "" },
      initialOption: "ALL_GUESTS",
      audienceOption: "ALL_GUESTS",
      audienceOptionFilter: undefined,
      audienceOptions: [
        {
          label: "_selectAllGuests",
          value: "ALL_GUESTS",
        },
        {
          label: "_selectPending",
          value: "GUESTS_PENDING",
        },
        {
          label: "_selectConfirmed",
          value: "GUESTS_CONFIRMED",
        },
        {
          label: "_selectCheckedIn",
          value: "GUESTS_CHECK_IN",
        },
        {
          label: '_selectDecline',
          value: "GUESTS_DECLINED"
        },
        {
          label: "_selectCustomField",
          value: "CUSTOM_FIELD",
          canExpandable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "team",
      "features",
      "isExhibitor",
      "memberCustomFieldMap",
      "memberCustomFieldMapArray",
      "printedGuests",
    ]),
    customFieldArray() {
      return this.customFieldMap ? Object.entries(this.customFieldMap) : [];
    },
  },
  watch: {
    event: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal && newVal.c19c) {
          this.statusOptions = [
            { value: "PENDING", label: "pending" },
            { value: "WAITING_VERIFICATION", label: "waiting verification" },
            { value: "CONFIRMED", label: "confirmed" },
            { value: "CHECK_IN", label: "check-in" },
            { value: "DECLINE", label: "declined" },
            { value: "WILL_BE_BACK", label: "will be back" },
            { value: "CHECKED_OUT", label: "check-out" },
          ];
        } else {
          this.statusOptions = [
            { value: "PENDING", label: "pending" },
            { value: "CONFIRMED", label: "confirmed" },
            { value: "CHECK_IN", label: "check-in" },
            { value: "DECLINE", label: "declined" },
            { value: "WILL_BE_BACK", label: "will be back" },
            { value: "CHECKED_OUT", label: "check-out" },
          ];
        }
      },
    },
    customFieldMap: {
      immediate: true,
      handler: function (newVal, oldVal) {
        const memberColumns = this.memberCustomFieldMap
          ? Object.entries(this.memberCustomFieldMap)
          : [];
        const customColumns = [...memberColumns, ...(newVal ? Object.entries(newVal) : [])].filter(
          ([slug, cField]) => cField.type !== TEXT_ONLY
        );
        for (const [index, column] of this.columns.entries()) {
          if (column.isCustomField) {
            let isExist = false;
            for (const [slug, cField] of customColumns) {
              if (column.prop === slug) {
                isExist = true;
              }
            }
            if (!isExist) {
              this.columns.splice(index, 1);
            }
          }
        }
        for (const [slug, cField] of customColumns) {
          let isExist = false;
          for (const column of this.columns) {
            if (column.prop === slug) {
              isExist = true;
            }
          }
          if (!isExist) {
            this.columns = [
              ...this.columns.slice(0, this.columns.length - 1),
              {
                prop: slug,
                label: cField.options.question,
                minWidth: cField.type === "OPEN_ANSWER" ? "150" : "250",
                isCustomField: true,
                source: cField.source,
              },
              ...this.columns.slice(this.columns.length - 1),
            ];
          }
        }
      },
    },
    timeSlotEnabled: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (typeof newVal === "boolean" && newVal) {
          const tempArray = this.audienceOptions;
          let found = false;
          tempArray.forEach((val) => {
            if (val.value === "TIME_SLOT") {
              found = true;
            }
          });
          if (!found) {
            tempArray.push({
              label: "_selectTimeSlot",
              value: "TIME_SLOT",
              canExpandable: true,
            });
            this.audienceOptions = [...tempArray];
          }
        }
      },
    },
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
    this.subscribeToUpdates();

    if (this.features && this.features.nProtocollo) {
      this.columns = [
        ...this.columns.slice(0, this.columns.length - 1),
        {
          prop: "nProtocollo",
          label: "N. Protocollo",
          minWidth: "150",
        },
        ...this.columns.slice(this.columns.length - 1),
      ];
    }
  },
  beforeDestroy() {
    this.unsubscribeFromUpdates();
  },
  methods: {
    onFilterChanged(filter) {
      this.customFieldFilter = filter;
      if (
        (this.audienceOption === "CUSTOM_FIELD" || this.audienceOption === "TIME_SLOT") &&
        filter &&
        filter.question &&
        filter.type
      ) {
        this.audienceOptionFilter = {
          customFieldFilter: { ...filter },
        };
      } else {
        this.audienceOptionFilter = undefined;
      }
      this.$refs.table.getTableData();
    },
    onAudienceSizeChanged(size) {
      this.audienceSize = size;
    },
    onAudienceOptionChanged(option) {
      this.hasChanges = true;
      this.audienceOption = option;
      this.audienceOptionFilter = undefined;
      this.customFieldFilter = { question: "", answer: "", type: "", source: "" };
      this.$refs.table.getTableData();
    },
    customFieldAnswerToString(...args) {
      return customFieldAnswerToString(...args);
    },
    onBulkAction: function (action, selectedRows = []) {
      console.log({ action, selectedRows });
    },
    async getData({ filter, page, query, sortParams, pageSize }) {
      const context = this;
      const input = {};
      if (sortParams && Object.keys(sortParams).length) {
        for (const cField of Object.keys(sortParams)) {
          input["sortField"] = cField;
          input["sortOrder"] = sortParams[cField];
          break;
        }
      } else {
        input["sortField"] = "status";
        input["sortOrder"] = -1;
      }
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GUEST_LIST,
          variables: {
            eventId: this.eventId,
            filter: { q: filter ? filter : undefined },
            pageInfo: {
              pageSize: pageSize,
              currentPage: page,
              sortField: input["sortField"],
              sortOrder: input["sortOrder"],
            },
            customFields: [],
            audienceOption: this.audienceOption,
            audienceOptionFilter: this.audienceOptionFilter,
          },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            context.$router.push("/").catch((_) => {});
            return;
          }
          if (response.data && response.data.guests) {
            const payload = response.data.guests;

            context.totalGuests = payload.total;

            return {
              data: payload.data,
              total: payload.total,
            };
          }
        })
        .catch((error) => {
          // context.$router.push("/").catch(_ => {});
          console.log(error);
        });
    },
    onUpdateMultipleChoiceMember(row, slug, value) {
      let result = [];
      if (this.memberCustomFieldMap[slug] && this.memberCustomFieldMap[slug].options.answers) {
        if (Array.isArray(value)) {
          let answer;
          for (const answerSlug of value) {
            answer = this.memberCustomFieldMap[slug].options.answers[answerSlug];
            if (answer) {
              const newAnswer = {
                key: answer.key,
                label: answer.label,
              };
              result = result && result.length > 0 ? [...result, newAnswer] : [newAnswer];
            }
          }
        }
      }
      this.editItem.memberCustomFieldDataMap[slug] = result;
      this.markRowModified(row, { updateCustomFields: true });
    },
    onUpdateMultipleChoice(row, slug, value) {
      let result = [];
      if (this.customFieldMap[slug] && this.customFieldMap[slug].options.answers) {
        if (Array.isArray(value)) {
          let answer;
          for (const answerSlug of value) {
            answer = this.customFieldMap[slug].options.answers[answerSlug];
            if (answer) {
              const newAnswer = {
                key: answer.key,
                label: answer.label,
              };
              result = result && result.length > 0 ? [...result, newAnswer] : [newAnswer];
            }
          }
        }
      }
      this.editItem.customFieldDataMap[slug] = result;
      this.markRowModified(row, { updateCustomFields: true });
    },
    onUpdateTimeSlotMember(row, slug, value) {
      let result = null;
      if (this.memberCustomFieldMap[slug] && this.memberCustomFieldMap[slug].options.answers) {
        const answer = this.memberCustomFieldMap[slug].options.answers[value];
        if (answer) {
          result = {
            key: answer.key,
            label: answer.label,
            startDate: answer.startDate,
            endDate: answer.endDate,
            title: answer.title,
          };
        }
      }
      this.editItem.memberCustomFieldMap[slug] = result;
      this.markRowModified(row, { updateCustomFields: true });
    },
    onUpdateTimeSlot(row, slug, value) {
      let result = null;
      if (this.customFieldMap[slug] && this.customFieldMap[slug].options.answers) {
        const answer = this.customFieldMap[slug].options.answers[value];
        if (answer) {
          result = {
            key: answer.key,
            label: answer.label,
            startDate: answer.startDate,
            endDate: answer.endDate,
            title: answer.title,
          };
        }
      }
      this.editItem.customFieldDataMap[slug] = result;
      this.markRowModified(row, { updateCustomFields: true });
    },
    closeRow(row) {
      row.loading = false;
      row.edit = false;
      this.editItem = {
        memberName: "",
        memberSurname: "",
        memberEmail: "",
        memberTelephone: "",
        maxAcquaintance: 0,
        listEmails: [],
        status: "",
        customFieldDataMap: {},
        answerList: {},
        memberCustomFieldDataMap: {},
        memberAnswerList: {},
      };
      this.editItemCopy = { ...this.editItem };
      this.updateCustomFields = false;
      this.updateEmailList = false;
    },
    openRow(row) {
      // close open rows
      for (const [index, item] of this.$refs.table.tableData.entries()) {
        if (item.id !== row.id && item.edit) {
          item.edit = false;
        }
      }
      // start modifying
      const tempEmailList = [];
      if (row.member && row.member.listEmails) {
        for (const email of row.member.listEmails) {
          tempEmailList.push({ title: email, edit: false });
        }
      }
      const tempAnswerList = {};
      for (const [key, cFieldData] of Object.entries(row.customFieldDataMap || {})) {
        if (Array.isArray(cFieldData)) {
          tempAnswerList[key] = cFieldData.map((answer) => answer.key);
        } else if (typeof cFieldData !== "string") {
          if (cFieldData && cFieldData.key) {
            tempAnswerList[key] = cFieldData.key;
          }
        }
      }
      const tempMemberAnswerList = {};
      for (const [key, cFieldData] of Object.entries(row.member.memberCustomFieldDataMap || {})) {
        if (Array.isArray(cFieldData)) {
          tempMemberAnswerList[key] = cFieldData.map((answer) => answer.key);
        } else if (typeof cFieldData !== "string") {
          if (cFieldData && cFieldData.key) {
            tempMemberAnswerList[key] = cFieldData.key;
          }
        }
      }
      this.answerList = tempAnswerList;
      this.memberAnswerList = tempMemberAnswerList;
      this.editItem = {
        memberName: row.member.name,
        memberSurname: row.member.surname,
        memberEmail: row.member.email,
        memberTelephone: row.member.telephone,
        maxAcquaintance: row.maxAcquaintance,
        listEmails: tempEmailList,
        status: row.status,
        customFieldDataMap: row.customFieldDataMap ? row.customFieldDataMap : {},
        answerList: tempAnswerList,
        memberCustomFieldDataMap: row.member.memberCustomFieldDataMap
          ? row.member.memberCustomFieldDataMap
          : {},
        memberAnswerList: tempMemberAnswerList,
      };
      this.editItemCopy = { ...this.editItem };

      row.edit = true;
    },
    async saveRow(row) {
      if (row.shouldUpdate === true) {
        row.loading = true;
        const tempItem = { ...this.editItem };
        const tempItemCopy = { ...this.editItemCopy };

        const guestId = row.id;
        const memberId = row.member.id;
        const guestUpdates = {};
        const memberUpdates = {};
        let controlLimit = false;
        if (tempItem.memberName !== tempItemCopy.memberName) {
          memberUpdates["name"] = tempItem.memberName;
        }
        if (tempItem.memberSurname !== tempItemCopy.memberSurname) {
          memberUpdates["surname"] = tempItem.memberSurname;
        }
        if (tempItem.memberEmail !== tempItemCopy.memberEmail) {
          memberUpdates["email"] = tempItem.memberEmail;
        }
        if (tempItem.memberTelephone !== tempItemCopy.memberTelephone) {
          memberUpdates["telephone"] = tempItem.memberTelephone;
        }
        if (tempItem.status !== tempItemCopy.status) {
          guestUpdates["status"] = tempItem.status.toUpperCase();
          if (
            tempItemCopy.status === "PENDING" &&
            ["CONFIRMED", "CHECK_OUT", "WILL_BE_BACK", "CHECK_IN"].indexOf(tempItem.status) > -1
          ) {
            controlLimit = true;
          }
        }
        if (tempItem.maxAcquaintance !== tempItemCopy.maxAcquaintance) {
          guestUpdates["maxAcquaintance"] = tempItem.maxAcquaintance;
        }

        if (this.updateEmailList) {
          const newEmailList = [];
          this.editItem.listEmails.forEach((email) =>
            newEmailList.push(email.title.toLowerCase().trim())
          );
          memberUpdates["listEmails"] = newEmailList;
        }
        if (this.updateCustomFields) {
          guestUpdates["customFieldDataMap"] = tempItem.customFieldDataMap;
        }
        if (this.updateCustomFields) {
          memberUpdates["memberCustomFieldDataMap"] = tempItem.memberCustomFieldDataMap;
        }
        await this.onBeforeUpdateGuest({
          memberId,
          guestId,
          guestUpdates,
          memberUpdates,
          controlLimit,
        });

        //localBadgePrint
        if (
          this.localPrintBadge &&
          tempItem.status === "CHECK_IN" &&
          tempItem.status !== tempItemCopy.status
        ) {
          this.printBadge(row);
        }
      }
      row.loading = false;
      row.edit = false;
      this.editItem = {
        memberName: "",
        memberSurname: "",
        memberEmail: "",
        memberTelephone: "",
        maxAcquaintance: 0,
        listEmails: [],
        status: "",
        customFieldDataMap: {},
        answerList: {},
        memberCustomFieldDataMap: {},
        memberAnswerList: {},
      };
      this.editItemCopy = { ...this.editItem };
      this.updateEmailList = false;
      if (this.updateCustomFields) {
        this.$emit("customFieldsChanged", null);
      }
      this.updateCustomFields = false;
    },
    subscribeToUpdates() {
      const context = this;
      this.guestNewsSubscription = this.$apollo
        .subscribe({
          query: SUBSCRIBE_TO_GUEST_NEWS,
          variables: { eventId: this.eventId },
        })
        .subscribe(({ data, errors }) => {
          if (errors) {
            console.log(errors);
            return;
          }
          if (data && data.guestNews) {
            const payload = data.guestNews;
            const updatedItems = payload.data;
            const tempArray = context.$refs.table.tableData;
            // console.log("GuestListContainer@subscribeToUpdates");
            // console.log({ ...payload } );
            if (payload.mutation === "CREATED") {
              // let itExist = false;
              // let importedCount = 0;
              // const importedItems = [];
              // for (const updatedItem of updatedItems) {
              //   for (const [index, existingItem] of context.$refs.table.tableData.entries()) {
              //     if (existingItem.id === updatedItem.id) {
              //       itExist = true;
              //       break;
              //     }
              //   }
              //   if (!itExist) {
              //     tempArray = [updatedItem, ...tempArray];
              //     context.$refs.table.total++;
              //     importedCount++;
              //   }
              // }
              // context.$refs.table.tableData = [...tempArray];
              for (const updatedItem of updatedItems) {
                if (updatedItem && updatedItem.id && context.autoPrintBadge) {
                  // fire badge print
                  if (!context.printedGuests[updatedItem.id] && updatedItem.status === "CHECK_IN") {
                    // print
                    context.$store
                      .dispatch("getBadge", updatedItem)
                      .then((result) => {
                        if (result.status === "SUCCESS" && result.pdf) {
                          const arr = new Uint8Array(result.pdf.data);
                          const blob = new Blob([arr], { type: "application/pdf" });
                          const href = window.URL.createObjectURL(blob, {
                            type: "application/pdf",
                          });

                          //iframe print
                          const iframe = document.createElement("iframe");
                          // iframe.id = 'pdfIframe'
                          iframe.className = "pdfIframe";
                          document.body.appendChild(iframe);
                          iframe.style.display = "none";
                          iframe.onload = function () {
                            setTimeout(() => {
                              iframe.focus();
                              iframe.contentWindow.print();
                              URL.revokeObjectURL(href);
                              // document.body.removeChild(iframe)
                            }, 1);
                          };
                          iframe.src = href;
                          context.$store.commit("UPDATE_PRINTED_GUESTS", {
                            ...context.printedGuests,
                            [updatedItem.id]: updatedItem,
                          });
                        }
                      })
                      .catch((error) => {
                        if (error.data && error.data.status && error.data.status === "FAILED") {
                          this.$notify({
                            title: "Oops !",
                            message: error.data.message,
                            position: "bottom-right",
                            type: "error",
                            duration: 3000,
                          });
                        }
                      });
                  } else if (
                    context.printedGuests[updatedItem.id] &&
                    updatedItem.status !== "CHECK_IN"
                  ) {
                    // revert print
                    context.$store.commit("UPDATE_PRINTED_GUESTS", {
                      ...context.printedGuests,
                      [updatedItem.id]: undefined,
                    });
                  }
                }
              }
              context.$refs.table.getTableData();
            } else if (payload.mutation === "UPDATED") {
              for (const updatedItem of updatedItems) {
                if (updatedItem && updatedItem.id && context.autoPrintBadge) {
                  // fire badge print
                  if (!context.printedGuests[updatedItem.id] && updatedItem.status === "CHECK_IN") {
                    // print
                    context.$store
                      .dispatch("getBadge", updatedItem)
                      .then((result) => {
                        if (result.status === "SUCCESS" && result.pdf) {
                          const arr = new Uint8Array(result.pdf.data);
                          const blob = new Blob([arr], { type: "application/pdf" });
                          const href = window.URL.createObjectURL(blob, {
                            type: "application/pdf",
                          });

                          //iframe print
                          const iframe = document.createElement("iframe");
                          // iframe.id = 'pdfIframe'
                          iframe.className = "pdfIframe";
                          document.body.appendChild(iframe);
                          iframe.style.display = "none";
                          iframe.onload = function () {
                            setTimeout(() => {
                              iframe.focus();
                              iframe.contentWindow.print();
                              URL.revokeObjectURL(href);
                              // document.body.removeChild(iframe)
                            }, 1);
                          };
                          iframe.src = href;
                          context.$store.commit("UPDATE_PRINTED_GUESTS", {
                            ...context.printedGuests,
                            [updatedItem.id]: updatedItem,
                          });
                        }
                      })
                      .catch((error) => {
                        if (error.data && error.data.status && error.data.status === "FAILED") {
                          this.$notify({
                            title: "Oops !",
                            message: error.data.message,
                            position: "bottom-right",
                            type: "error",
                            duration: 3000,
                          });
                        }
                      });
                  } else if (
                    context.printedGuests[updatedItem.id] &&
                    updatedItem.status !== "CHECK_IN"
                  ) {
                    // revert print
                    context.$store.commit("UPDATE_PRINTED_GUESTS", {
                      ...context.printedGuests,
                      [updatedItem.id]: undefined,
                    });
                  }
                }
                for (const [index, existingItem] of context.$refs.table.tableData.entries()) {
                  if (existingItem.id === updatedItem.id) {
                    tempArray[index] = { ...tempArray[index], ...updatedItem, edit: false };
                  }
                }
              }
              context.$refs.table.tableData = [...tempArray];
            } else if (payload.mutation === "DELETED") {
              let tempDiff = 0;
              for (const updatedItem of updatedItems) {
                for (const [index, existingItem] of context.$refs.table.tableData.entries()) {
                  if (existingItem.id === updatedItem.id) {
                    tempArray.splice(index, 1);
                    tempDiff++;
                  }
                }
              }
              context.$refs.table.total -= tempDiff;
              context.$refs.table.tableData = [...tempArray];
            }
            this.$root.bus.emit("revalidate-audience-count", null);
          }
        });

      this.guestListImportTaskSubscription = this.$apollo
        .subscribe({
          query: SUBSCRIBE_TO_GUEST_LIST_IMPORT,
          variables: { eventId: this.eventId },
        })
        .subscribe(({ data, errors }) => {
          if (errors) {
            console.log(errors);
            return;
          }
          if (data && data.uploadGuestList) {
            const payload = data.uploadGuestList;

            context.uploadStatus = payload.status;
            context.uploadStatusMessage = payload.message;
            context.uploadProgress = payload.progress;
            if (payload.status === "NULL") {
              // initial case
            } else if (payload.status === "PENDING") {
              // PENDING
            } else if (payload.status === "STARTED") {
              // STARTED
            } else if (payload.status === "PROCESSING") {
              // Processing
            } else if (payload.status === "DONE") {
              // Done
              if (payload.message === "GUEST_LIST_FULL") {
                this.$root.bus.emit(
                  "simpleToast",
                  this.string.guestlist._maxGuestSizeReached + this.team.guestListLimit
                );
              } else if (payload.discardedGuestList && payload.discardedGuestList.length) {
                const reportColumns = [
                  {
                    label: "Name",
                    field: "name",
                  },
                  {
                    label: "Surname",
                    field: "surname",
                  },
                  {
                    label: "Email",
                    field: "email",
                    dataFormat: (value) => {
                      return value.includes("internal.penguinpass.it") ? "" : value;
                    },
                  },
                  {
                    label: "Row number in uploaded file",
                    field: "rowNumber",
                  },
                  {
                    label: "Reason to Discard",
                    field: "reason",
                  },
                ];
                const c = context.$createElement(
                  "vue-excel-xlsx",
                  {
                    class: ["discardStyle"],
                    props: {
                      columns: reportColumns,
                      data: payload.discardedGuestList,
                      filename: "discarded_guest_list",
                      sheetname: "Sheet 1",
                    },
                  },
                  context.string.guestlist._discard_message
                );
                context.$notify({
                  title: "Oops !",
                  message: c,
                  type: "warning",
                  duration: 0,
                  offset: 100,
                });
              }

              context.uploadStatus = "NULL";
              context.uploadStatusMessage = "";

              if (payload.hasNewCustomFields) {
                context.$emit("customFieldsChanged", null);
              }
            }
          }
        });
    },
    unsubscribeFromUpdates() {
      try {
        if (this.guestListImportTaskSubscription) {
          this.guestListImportTaskSubscription.unsubscribe();
          this.guestListImportTaskSubscription = null;
        }
        if (this.guestNewsSubscription) {
          this.guestNewsSubscription.unsubscribe();
          this.guestNewsSubscription = null;
        }
      } catch (e) {}
    },
    toggleInsertSingle() {
      this.tabVisible = true;
      this.tabValue = "insertsingle";
    },
    toggleInsertHubilo() {
      this.tabVisible = true;
      this.tabValue = "inserthubilo";
    },
    toggleUploadList() {
      this.tabVisible = true;
      this.tabValue = "uploadlist";
    },
    closeTab(e) {
      this.tabVisible = false;
      this.tabValue = "";
    },
    saveHubiloId(e) {
      this.closeTab(e);
      this.$apollo.mutate({
        mutation: UPDATE_EVENT,
        variables: {
          eventId: this.event.id,
          updateEventInput: {
            hubiloEventId: this.newHubiloEventId,
          },
        }
      }).then((response) => {
          if (response.errors) {
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.updateEvent) {
            this.$root.bus.emit("simpleToast", this.string.guestlist._hubiloSaved);
          }
        });
    },
    reloadHubiloEvent() {
      this.$root.bus.emit("simpleToast", this.string.guestlist._loadingMessage);
      this.$apollo
        .mutate({
          mutation: IMPORT_HUBILO_EVENT,
          variables: {
            eventId: this.event.id,
            hubiloEventId: this.newHubiloEventId,
          },
        })
        .then((response) => {
          this.loadingEffectshow = true;
          if (response.errors) {
            console.log(response.errors);
            this.loadingEffectshow = false;
            return;
          }
          if (response.data && response.data.importHubiloEvent) {
            const payload = response.data.importHubiloEvent;
            this.$root.bus.emit("simpleToast", this.string.guestlist._listadded);
            this.$emit("customFieldsChanged", null);
            this.$refs.table.getTableData();
            this.loadingEffectshow = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    markRowModified(row, options = {}) {
      row.shouldUpdate = true;
      if (typeof options.updateEmailList === "boolean") {
        this.updateEmailList = options.updateEmailList;
      }
      if (typeof options.updateCustomFields === "boolean") {
        this.updateCustomFields = options.updateCustomFields;
      }
    },
    addNewEmail(row) {
      if (this.newEmail) {
        if (!isEmail(this.newEmail)) {
          return this.$root.bus.emit("simpleToast", "Email not valid");
        }
        for (const emailObj of this.editItem.listEmails) {
          if (emailObj.title === this.newEmail) {
            return this.$root.bus.emit("simpleToast", "Email already present");
          }
        }
        this.editItem = {
          ...this.editItem,
          listEmails: [...this.editItem.listEmails, { title: this.newEmail, edit: false }],
        };
        this.newEmail = "";
        this.markRowModified(row, { updateEmailList: true });
      }
    },
    deleteEmail(email) {
      const deleteIndex = this.editItem.listEmails.indexOf(email);
      const tempListEmail = this.editItem.listEmails.splice(deleteIndex, 1);
      this.listEmails = tempListEmail;
    },
    setMaxAcquaintance(value) {
      this.editItem.maxAcquaintance = value;
    },
    formatProgress(percentage) {
      return `${percentage}%` + (this.uploadStatusMessage ? " " + this.uploadStatusMessage : " ");
    },
    async fetchDataAndExportReport(checkinOnly = false) {
      const context = this;
      this.exportingReport = true;
      this.$root.bus.emit("simpleToast", this.string.report._export_request_received);
      await this.loadReportData()
        .then((_) => {
          const checkedStatuses = ["CHECK_IN", "CHECKED_OUT", "WILL_BE_BACK"];
          const tempData =
            checkinOnly === true
              ? context.reportData.filter((item) => {
                  if (checkedStatuses.indexOf(item.status) !== -1) {
                    return item;
                  }
                })
              : context.reportData;
          if (tempData.length > 0) {
            const c = context.$createElement(
              "vue-excel-xlsx",
              {
                class: ["discardStyle"],
                props: {
                  columns: context.reportColumns,
                  data: tempData,
                  filename: "guest_list_report",
                  sheetname: "Sheet 1",
                },
              },
              context.string.report._export_request_finished
            );
            context.$notify({
              title: "Success!",
              message: c,
              type: "success",
              duration: 0,
              offset: 100,
            });
          } else {
            context.$root.bus.emit("simpleToast", context.string.report._export_empty);
          }
        })
        .catch((error) => {
          console.log(error.message);
          context.$root.bus.emit("simpleToast", context.string.report._export_failed);
        });
      this.exportingReport = false;
    },
    async fetchDataAndExportPlusOnes() {
      const context = this;
      this.exportingReport = true;
      this.$root.bus.emit("simpleToast", this.string.report._export_request_received);
      await this.loadReportData()
        .then((_) => {
          const tempData = context.reportData;
          if (tempData.length > 0) {
            const plusOnesList = [];
            //compute all guests and create a new set only plus ones
            tempData.forEach((rowItem) => {
              console.log("rowItem", rowItem);
              if (rowItem.acquaintanceInfoList) {
                rowItem.acquaintanceInfoList.forEach((extraGuest) => {
                  plusOnesList.push({
                    alternativeEmails: "",
                    maxAcquaintance: this.maxAcquaintance,
                    status: "PENDING",
                    tel: "",
                    invitedFrom: `${rowItem.member.name} ${rowItem.member.surname}`,
                    member: rowItem.member,
                    customFieldDataMap: rowItem.customFieldDataMap,
                    ...extraGuest,
                  });
                });
              }
            });

            const c = context.$createElement(
              "vue-excel-xlsx",
              {
                class: ["discardStyle"],
                props: {
                  columns: context.plusOneColumns,
                  data: plusOnesList,
                  filename: "guest_list_plus_ones",
                  sheetname: "Sheet 1",
                },
              },
              context.string.report._export_request_finished
            );
            context.$notify({
              title: "Success!",
              message: c,
              type: "success",
              duration: 0,
              offset: 100,
            });
          } else {
            context.$root.bus.emit("simpleToast", context.string.report._export_empty);
          }
        })
        .catch((error) => {
          console.log(error.message);
          context.$root.bus.emit("simpleToast", context.string.report._export_failed);
        });
      this.exportingReport = false;
    },
    async fetchActivitiesAndExport() {
      const context = this;
      if (!this.exportingReport) {
        this.exportingReport = true;
        this.$root.bus.emit("simpleToast", this.string.report._export_request_received);
        await this.loadActivityReportData()
          .then((_) => {
            if (this.activityReportData.length > 0) {
              const c = context.$createElement(
                "vue-excel-xlsx",
                {
                  class: ["discardStyle"],
                  props: {
                    columns: this.activityReportColumns,
                    data: this.activityReportData,
                    filename: "guest_activity_report",
                    sheetname: "Sheet 1",
                  },
                },
                context.string.report._export_request_finished
              );
              context.$notify({
                title: "Success!",
                message: c,
                type: "success",
                duration: 0,
                offset: 100,
              });
            } else {
              context.$root.bus.emit("simpleToast", context.string.report._export_empty);
            }
          })
          .catch((error) => {
            console.log(error.message);
            context.$root.bus.emit("simpleToast", context.string.report._export_failed);
          });
        this.exportingReport = false;
      } else {
      }
    },
    async fetchAttachmentsAndExport(filter, page, query, sortParams, pageSize) {
      const context = this;
      const input = {};
      if (!this.exportingReport) {
        this.exportingReport = true;
        return this.$apollo
          .query({
            fetchPolicy: "no-cache",
            query: GUEST_LIST,
            variables: {
              eventId: this.eventId,
              pageInfo: {
                pageSize: pageSize,
                currentPage: page,
                sortField: input["sortField"],
                sortOrder: input["sortOrder"],
              },
              customFields: [],
              audienceOption: this.audienceOption,
              audienceOptionFilter: this.audienceOptionFilter,
            },
          })
          .then((response) => {
            if (response.errors) {
              console.log(response.errors);
              context.$router.push("/").catch((_) => {});
              return;
            }
            if (response.data && response.data.guests) {
              const payload = response.data.guests;
              let urls = [];
              payload.data.forEach((row) => {
                urls.push(row.customFieldDataMap["cvattachment"]);
              });

              let zip = new JSZip();

              for (const [slug, cField] of Object.entries(this.customFieldMap)) {
                urls = [];
                payload.data.forEach((row) => {
                  if (
                    row.customFieldDataMap[slug] &&
                    typeof row.customFieldDataMap[slug] === "string" &&
                    row.customFieldDataMap[slug].indexOf("://") > -1 &&
                    (row.customFieldDataMap[slug].indexOf(".jpg") > -1 ||
                      row.customFieldDataMap[slug].indexOf(".jpeg") > -1 ||
                      row.customFieldDataMap[slug].indexOf(".pdf") > -1 ||
                      row.customFieldDataMap[slug].indexOf(".doc") > -1 ||
                      row.customFieldDataMap[slug].indexOf(".docx") > -1)
                  ) {
                    urls.push(row.customFieldDataMap[slug]);
                  }
                });
                if (urls.length === 0) continue;

                const folder = zip.folder(slug);
                urls.forEach((url) => {
                  const blobPromise = fetch(url).then((r) => {
                    if (r.status === 200) return r.blob();
                    return Promise.reject(new Error(r.statusText));
                  });
                  const name = url.substring(url.lastIndexOf("/"));
                  folder.file(name, blobPromise);
                });
              }

              zip
                .generateAsync({ type: "blob" })
                .then((blob) => saveAs(blob, "attachments.zip"))
                .catch((e) => console.log(e));

              this.exportingReport = false;
            }
          })
          .catch((error) => {
            // context.$router.push("/").catch(_ => {});
            console.log(error);
          });
      } else {
      }
    },

    // api calls
    deleteListAllFunction() {
      if (confirm(this.string.guestlist._sure)) {
        const context = this;
        context.loadingEffectshow = true;
        return this.$apollo
          .mutate({
            mutation: DELETE_ALL,
            variables: {
              eventId: this.eventId,
            },
          })
          .then((value) => {
            if (value.errors) {
              console.log("error " + value.errors);
              context.loadingEffectshow = false;
            } else {
              context.loadingEffectshow = false;
              context.$root.bus.emit("simpleToast", context.string.guestlist._itemCancellato);
              // context.$emit("guestListDeleted", {});
              context.$refs.table.tableData = [];
              context.$refs.table.total = 0;
              this.$emit("customFieldsChanged", null);
            }
          })
          .catch((reason) => {
            console.log(reason.message);
            context.loadingEffectshow = false;
            context.$root.bus.emit("simpleToast", "failed");
          });
      }
    },
    async setEventMaxAcquaintance(maxAcquaintance) {
      const context = this;
      context.loadingEffectshow = true;
      await this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            eventId: context.eventId,
            updateEventInput: {
              maxGuestsPerMember: maxAcquaintance,
            },
          },
        })
        .then((value) => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
          } else {
            if (value.data && value.data.updateEvent) {
            }
          }
        })
        .catch((reason) => {
          console.log(reason.message);
          context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
        });
      await this.$apollo
        .mutate({
          mutation: UPDATE_GUEST_LIST_MAX_ACQUAINTANCE,
          variables: {
            eventId: context.eventId,
            maxAcquaintance: maxAcquaintance,
          },
        })
        .then((value) => {
          console.log(value);
          if (value.errors) {
            console.log("error " + value.errors);
            context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
          } else {
            if (value.data.setGuestListMaxAcquaintance === "SUCCESS") {
              context.$refs.table.getTableData();
              // context.$emit("guestDataChanged", { refreshData: true });
              context.$root.bus.emit("simpleToast", context.string.eventPad._successmessage);
            }
          }
        })
        .catch((reason) => {
          console.log(reason.message);
          context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
        });
      this.loadingEffectshow = false;
    },
    checkRow(row) {
      const context = this;
      context.loadingEffectshow = true;
      return this.$apollo
        .mutate({
          mutation: UPDATE_GUEST_BY_ID,
          variables: {
            guestId: row.id,
            eventId: this.eventId,
            guestInput: {
              status: "CHECK_IN",
            },
          },
        })
        .then((value) => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.loadingEffectshow = false;
          } else {
            context.loadingEffectshow = false;
            context.$root.bus.emit("simpleToast", context.string.datagrid._guest_checkin);
            if(this.localPrintBadge ){
              this.printBadge(row);
            }
            // context.$emit("guestDataChanged", {});
          }
        })
        .catch((reason) => {
          console.log(reason.message);
          context.$root.bus.emit("simpleToast", "failed");
          context.loadingEffectshow = false;
        });
    },
    deleteRow(row) {
      this.$confirm(
        `${this.string.teamPage._warning_remove} ${
          row.member
            ? row.member.email.includes("internal.penguinpass.it")
              ? ""
              : row.member.email
            : ""
        }?`
      )
        .then((_) => {
          const context = this;
          context.loadingEffectshow = true;
          return this.$apollo
            .mutate({
              mutation: DELETE_ONE_GUEST,
              variables: {
                eventId: this.eventId,
                memberListId: row.id,
              },
            })
            .then((value) => {
              if (value.errors) {
                console.log("error " + value.errors);
                context.loadingEffectshow = false;
              } else {
                // context.unsetItemFromGridData(row.id);
                // context.$emit("guestDataChanged", {});
                context.loadingEffectshow = false;
                context.$root.bus.emit("simpleToast", context.string.guestlist._itemCancellato);
              }
            })
            .catch((reason) => {
              console.log(reason.message);
              context.$root.bus.emit("simpleToast", "failed");
              context.loadingEffectshow = false;
            });
        })
        .catch((_) => {});
    },
    async onBeforeUpdateGuest({ controlLimit, ...rest }) {
      if (
        controlLimit &&
        this.maxGuestsPerEvent > 0 &&
        this.confirmationPlusOne >= this.maxGuestsPerEvent
      ) {
        return this.$root.bus.emit("simpleToast", this.string.guestlist._maxAttendeeExceeded);
      }
      return this.onUpdateGuest(rest);
    },
    async onUpdateGuest({ memberId, guestId, guestUpdates, memberUpdates }) {
      // console.log("GuestListContainer@onUpdateGuest: ");
      // console.log({ memberId, guestId, guestUpdates, memberUpdates });
      const context = this;
      // context.loadingEffectshow = true;
      let hasError = false;
      let message = "";
      if (Object.keys(memberUpdates).length > 0) {
        await this.$apollo
          .mutate({
            mutation: UPDATE_MEMBER_BY_ID,
            variables: {
              memberId,
              memberInput: memberUpdates,
            },
          })
          .then((value) => {
            if (value.errors) {
              console.log("error " + value.errors);
              hasError = true;
              message = "Unexpected Error";
            } else {
              message = context.string.guestlist._guestupdated;
            }
          })
          .catch((error) => {
            // context.loadingEffectshow = false;
            const errorMessage =
              error.graphQLErrors && error.graphQLErrors.length > 0
                ? error.graphQLErrors[0].message
                : "";
            if (errorMessage === "MEMBER_EXISTS") {
              hasError = true;
              message = this.string.listSingleItem._emailAlreadyPresentError;
            }
          });
      }
      if (Object.keys(guestUpdates).length > 0 && !hasError) {
        await this.$apollo
          .mutate({
            mutation: UPDATE_GUEST_BY_ID,
            variables: {
              guestId,
              eventId: context.eventId,
              guestInput: guestUpdates,
            },
          })
          .then((value) => {
            if (value.errors) {
              console.log("error " + value.errors);
              hasError = true;
              message = "Unexpected Error";
            } else {
              message = context.string.guestlist._guestupdated;
              context.$emit("guestDataChanged", {});
            }
          })
          .catch((error) => {
            const errorMessage =
              error.graphQLErrors && error.graphQLErrors.length > 0
                ? error.graphQLErrors[0].message
                : "";
            if (errorMessage === "CUSTOM_FIELD_LIMITS") {
              hasError = true;
              message = "Selected time slot's limit has been reached.";
            }
          });
      }
      // context.loadingEffectshow = false;
      context.$root.bus.emit("simpleToast", message);
    },
    async loadReportData() {
      const context = this;
      await this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GUEST_LIST_FOR_REPORT,
          variables: { eventId: this.eventId },
        })
        .then(async (response) => {
          if (response.errors) {
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.Event) {
            this.reportData = response.data.Event.guestList;
            this.addToReportColumns();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadActivityReportData() {
      await this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_ACTIVITY_REPORT,
          variables: { eventId: this.eventId },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log(errors);
            return;
          }
          if (data && data.getActivityReport) {
            this.activityReportData = data.getActivityReport;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // Custom Fields Management for report data
    addToReportColumns() {
      const memberColumns = this.memberCustomFieldMap
        ? Object.entries(this.memberCustomFieldMap)
        : [];
      const customColumns = [
        ...memberColumns,
        ...(this.customFieldMap ? Object.entries(this.customFieldMap) : []),
      ];
            for (const [slug, cField] of customColumns) {
        let notExist = true;
        this.reportColumns.map((col) => {
          if (col.cFieldSlug === slug) {
            notExist = false;
          }
        });
        if (notExist) {
          if (cField.source !== "GUEST") {
            this.reportColumns.push({
              cFieldSlug: slug,
              label: cField.options.question,
              field: "member",
              dataFormat: (member) =>
                member && member.memberCustomFieldDataMap
                  ? customFieldAnswerToString(member.memberCustomFieldDataMap[slug])
                  : "",
            });
          } else {
            this.reportColumns.push({
              cFieldSlug: slug,
              label: cField.options.question,
              field: "customFieldDataMap",
              dataFormat: (customFieldDataMap) =>
                customFieldAnswerToString(customFieldDataMap[slug]),
            });
          }
        }

        //plusOneColumns as well
        notExist = true;
        this.plusOneColumns.map((col) => {
          if (col.cFieldSlug === slug) {
            notExist = false;
          }
        });
        if (notExist) {
          if (cField.source === "CONTACT") {
            this.plusOneColumns.push({
              cFieldSlug: slug,
              label: cField.options.question,
              field: "member",
              dataFormat: (member) =>
                member && member.memberCustomFieldDataMap
                  ? customFieldAnswerToString(member.memberCustomFieldDataMap[slug])
                  : "",
            });
          } else {
            this.plusOneColumns.push({
              cFieldSlug: slug,
              label: cField.options.question,
              field: "customFieldDataMap",
              dataFormat: (customFieldDataMap) =>
                customFieldAnswerToString(customFieldDataMap[slug]),
            });
          }
        }
      }
    },
    printBadge(row) {
      // print manually
      this.$store
        .dispatch("getBadge", row)
        .then((result) => {
          if (result.status === "SUCCESS" && result.pdf) {
            const arr = new Uint8Array(result.pdf.data);
            const blob = new Blob([arr], { type: "application/pdf" });
            const href = window.URL.createObjectURL(blob, { type: "application/pdf" });

            //iframe print
            const iframe = document.createElement("iframe");
            // iframe.id = 'pdfIframe'
            iframe.className = "pdfIframe";
            document.body.appendChild(iframe);
            iframe.style.display = "none";
            iframe.onload = function () {
              setTimeout(() => {
                iframe.focus();
                iframe.contentWindow.print();
                URL.revokeObjectURL(href);
                // document.body.removeChild(iframe)
              }, 1);
            };
            iframe.src = href;
            this.$store.commit("UPDATE_PRINTED_GUESTS", { ...this.printedGuests, [row.id]: row });
          }
        })
        .catch((error) => {
          if (error.data && error.data.status && error.data.status === "FAILED") {
            this.$notify({
              title: "Oops !",
              message: error.data.message,
              position: "bottom-right",
              type: "error",
              duration: 3000,
            });
          }
        });
    },
    downloadBadge(row) {
      this.$store
        .dispatch("getBadge", row)
        .then((result) => {
          if (result.status === "SUCCESS" && result.pdf) {
            const arr = new Uint8Array(result.pdf.data);
            const blob = new Blob([arr], { type: "application/pdf" });
            const href = window.URL.createObjectURL(blob, { type: "application/pdf" });
            const theLink = document.createElement("a");
            theLink.href = href;
            theLink.download = `${row.member.surname}_${row.member.name}_badge.pdf`;
            document.body.appendChild(theLink);
            theLink.click();
            document.body.removeChild(theLink);
          }
        })
        .catch((error) => {
          if (error.data && error.data.status && error.data.status === "FAILED") {
            this.$notify({
              title: "Oops !",
              message: error.data.message,
              position: "bottom-right",
              type: "error",
              duration: 3000,
            });
          }
        });
    },
  },
};
</script>

<style>
label.el-form-item_label {
  vertical-align: bottom;
  font-size: 20px;
  color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
  margin: 0;
}
.guest-list-container {
  padding: 0;
}
.status-pending {
  color: #eea236 !important;
  text-align: left;
  margin-right: 5px;
}
.status-active {
  color: #0ac407 !important;
  text-align: left;
  margin-right: 5px;
}
.status-inactive {
  color: crimson !important;
  text-align: left;
  margin-right: 5px;
}
.button-pair.first:hover,
.button-pair.second:hover {
  background: #fff !important;
  color: #333 !important;
}
.button-pair.second,
.button-pair.first {
  background: #333 !important;
  color: #f7a30c !important;
}
.form-section-title {
  padding-bottom: 5px;
  margin-top: 20px;
  color: #fff;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 18px;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}
.group-manager-title {
  color: #fff;
  font-size: 22px;
  margin-bottom: 20px;
}
.group-manager-actions {
  padding-bottom: 30px;
}
.group-manager-actions-button {
  padding: 5px;
}
/* .group-manager-actions-button:first-child,
    .group-manager-actions-button:last-child{
        padding-left: 15px;
    } */
.group-manager-actions-button a {
  cursor: pointer;
  display: block;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  color: #ffffff;
  border: 0 !important;
  border-radius: unset !important;
  background-color: #333333;
  transition: 1s ease;
}
.group-manager-actions-button a:hover {
  background-color: #dadada;
  color: #333333;
}
.group-manager-members,
.group-manager-members-list,
.group-manager-members-list > div {
  width: 100%;
}
.group-manager-members-list-no-data {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.group-manager-members-list-no-data i {
  font-size: 130px;
  margin-left: 35px;
  color: #f7a30c;
}
.group-manager-members-list-no-data-message {
  font-size: 30px;
  text-transform: uppercase;
}
.group-manager-members-list-no-data-message .underline {
  border-bottom: 2px solid #f7a30c;
}
.group-manager-members-list-no-data-hint {
  font-size: 25px;
  font-style: italic;
}

/** class for the transition NO REMOVE THIS */
.component-fade-fast-enter-active,
.component-fade-fast-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-fast-enter,
.component-fade-fast-leave-active {
  opacity: 0;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.9s ease;
}
.component-fade-enter,
.component-fade-leave-active,
.component-fade-leave-to {
  opacity: 0;
}
.p-table-cell {
  display: flex;
  align-items: center;
}
.p-table-cell,
.p-table-cell > div > input {
  height: 45px !important;
  text-align: center;
  width: 100%;
  color: white;
}
span.p-table-cell {
  line-height: 45px !important;
}
.p-table-cell > .el-button:focus,
.el-button:hover {
  color: black !important;
  background: white !important;
}
button.el-button.p-table-cell.el-button--default {
  color: white !important;
  background: #f7a30c !important;
}
a.action_link {
  transition: 1s ease;
  background-color: transparent !important;
  color: #dadada !important;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  padding: 5px !important;
}

a.action_link:hover {
  color: #f7a30c !important;
}

/* a.actions_link {
  display: inline-block;
  cursor: pointer;
  transition: 1s ease;
  background-color: transparent !important;
  color: #ffffff !important;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  padding: 0px !important;
  margin-top: 0px !important;
}
a.actions_link:hover {
  background-color: transparent !important;
  color: #000 !important;
} */

.downloads_style {
  padding-left: 15px;
  font-size: 18px;
}
.downloads_style > i {
  padding-right: 15px;
}
.linkStyle {
  display: inline;
  cursor: pointer;
}
button.linkStyle {
  background: transparent !important;
  border: transparent !important;
  cursor: pointer;
  padding: 1px 0;
}
.downloads_style > .linkStyle:hover {
  color: #f7a30c;
  text-decoration: underline;
}
button > .downloads_style > .linkStyle:hover {
  color: #f7a30c;
  text-decoration: underline;
}
.downloads_style > div > a:hover {
  color: #f7a30c !important;
}
a.simple {
  cursor: pointer;
  color: #333 !important;
  font-size: 20px !important;
  text-decoration: none;
  background-color: transparent !important;
}
a.simple:hover {
  cursor: pointer;
  color: #f7a30c;
  font-size: 20px !important;
  text-decoration: underline;
}
</style>
<style>
div.name-wrapper-2-borderless > .el-tag,
div.name-wrapper-2-borderless.el-popover__reference > .el-tag {
  padding: 0;
}

div.name-wrapper-2.el-popover__reference > .el-tag,
div.name-wrapper-2 > .el-tag,
div.name-wrapper-2-borderless > .el-tag,
div.name-wrapper-2-borderless.el-popover__reference > .el-tag {
  border: 0px;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  text-align: center;
}
div.name-wrapper-2.el-popover__reference > .el-tag:hover,
div.name-wrapper-2 > .el-tag:hover,
div.name-wrapper-2-borderless > .el-tag:hover,
div.name-wrapper-2-borderless.el-popover__reference > .el-tag:hover {
  color: #000;
}
.name-wrapper-2.el-popover__reference,
.name-wrapper-2 {
  text-align: center;
  border: 1px solid #f7a30c;
}
.name-wrapper-2-borderless.el-popover__reference,
.name-wrapper-2-borderless {
  text-align: center;
  border: 0px !important;
}
.audience-filter-area {
  margin: 20px 0;
}
</style>
